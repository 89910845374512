import ColorsType from '@/shareds/types/colors.type';
import React, { useMemo } from 'react';
import { Icon } from 'vkit/lib/components';
import classesBuilder from 'vkit/lib/shared/utils/classesBuilder';
import style from '@/components/iconBox/iconBox.module.scss';

interface IconBoxProps {
  size?: number;
  icon?: string;
  iconImageUrl?: string;
  bgTransparent?: boolean;
  backgroundColor?: ColorsType;
  color?: ColorsType;
  circle?: boolean;
}

const IconBox: React.FC<IconBoxProps> = ({
  size = 40,
  icon,
  iconImageUrl,
  bgTransparent,
  color = 'colorDefault',
  circle,
}) => {
  const iconSize = useMemo(() => size - 20, [size]);

  return (
    <div
      style={{
        width: size,
        height: size,
        minWidth: size,
      }}
      className={classesBuilder(style, {
        iconBox: true,
        transparent: bgTransparent,
        circle,
        [color]: Boolean(color),
      })}>
      {icon ? (
        <Icon color={color} name={icon} size={iconSize} />
      ) : (
        <div
          className={classesBuilder(style, {
            iconImage: true,
            [color]: Boolean(color),
          })}
          style={{
            width: iconSize,
            height: iconSize,
            mask: `url(${iconImageUrl}) no-repeat center / contain`,
            WebkitMask: `url(${iconImageUrl}) no-repeat center / contain`,
          }}
        />
      )}
    </div>
  );
};

export default IconBox;
