import { APPLICATION_GROUP_BY, Route } from '@/core/entities';
import { createRouteLoaderPageDetails } from '@/core/utils/routes.utils';
import { Main } from '@/core/views';
import { CADASTRAL_DATA_MATCHING_PERMISSIONS } from '@/modules/cadastralDataMatching';
import * as Pages from '@/modules/cadastralDataMatching/pages';

import { USER_TYPE } from '@/services/users/endpoints/users';
import ICONS from '@/shareds/constants/icons';

const pageName = 'Bate Cadastral';

export enum ROUTE_CADASTRAL_DATA_MATCHING {
  LIST = 'cadastral-data-matching-list',
  DETAIL = 'cadastral-data-matching-detail',
  UPLOAD = 'cadastral-data-matching-upload',
}

const Routes: Route[] = [
  {
    path: '/companies/:companyId/cadastral-data-matchings',
    Component: Main,
    id: ROUTE_CADASTRAL_DATA_MATCHING.LIST,
    meta: {
      groupedBy: APPLICATION_GROUP_BY.COMPANY,
      showApplicationMenu: true,
      customImageUrl: ICONS.CADASTRAL_DATA_MATCHING,
      nameMenu: pageName,
      permission: {
        type: USER_TYPE.BROKER,
        values: [CADASTRAL_DATA_MATCHING_PERMISSIONS.CREATE],
      },
    },
    loader() {
      return createRouteLoaderPageDetails({
        title: 'Bate Cadastral',
      });
    },
    children: [
      {
        index: true,
        Component: Pages.List,
      },
      {
        path: ':cadastralDataMatchingId/details',
        id: ROUTE_CADASTRAL_DATA_MATCHING.DETAIL,
        Component: Pages.Details,
        loader() {
          return createRouteLoaderPageDetails({
            groupedBy: APPLICATION_GROUP_BY.COMPANY,
            customImageUrl: ICONS.CADASTRAL_DATA_MATCHING,
            name: pageName,
            hasGoBackAction: true,
            title: 'Bate Cadastral | Detalhes',
          });
        },
      },
      {
        path: 'actions/upload',
        id: ROUTE_CADASTRAL_DATA_MATCHING.UPLOAD,
        Component: Pages.Upload,
        loader() {
          return createRouteLoaderPageDetails({
            groupedBy: APPLICATION_GROUP_BY.COMPANY,
            hasGoBackAction: true,
            name: 'Envio de arquivo',
            icon: ICONS.UPLOAD,
            title: 'Bate Cadastral | Upload',
          });
        },
      },
    ],
  },
];

export default Routes;
