import { Space } from '@/components';
import DataTableErrors from '@/shared/components/dataTableErrors/dataTableErrors';
import React from 'react';
import { MovesValidationError } from '@/services/files/endpoints/moves';
import { Button, Text } from 'vkit/lib/components';
import { Grid } from 'vkit/lib/context';
import { CADASTRAL_POSITION_UPLOAD_STEPS } from '@/modules/cadastralPosition/entities/cadastralPosition.enum';

export interface ErrosProps {
  errors: MovesValidationError;
  setCurrentStep: (step: CADASTRAL_POSITION_UPLOAD_STEPS) => void;
}

const Errors: React.FC<ErrosProps> = ({ errors, setCurrentStep }) => {
  return (
    <>
      <Text
        applyBaseColor
        color='danger'
        fontWeight='bold'
        icon='alert-triangle-outline'
        iconColor='danger'
        margin='0 0 16px'
        padding='8px 16px'
        rounded
        size='larger'
        value={`Ops! Não conseguimos processar o arquivo de posição cadastral. 
          Detectamos algumas informações incorretas ou ausentes nos campos destacados na tabela abaixo. 
          Por favor, verifique os seguintes campos abaixo e tente novamente.`}
      />

      <DataTableErrors details={errors.details} />

      <Space horizontal />

      <Grid alignContent='right'>
        <Button
          label='Enviar novo arquivo'
          onClick={() => setCurrentStep(CADASTRAL_POSITION_UPLOAD_STEPS.FORM)}
        />
      </Grid>
    </>
  );
};

export default Errors;
