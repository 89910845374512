import useCompany from '@/shared/hooks/useCompany/useCompany';
import { useState } from 'react';

import { get } from 'lodash';

import { FilesService } from '@/shared/services';

import {
  MovesValidationError,
  movesValidationErrorInitialState,
} from '@/services/files/endpoints/moves';
import { getMessageError } from '@/utils/getMessageError';
import { ToastStatusGlobal } from '@/components';

import { CADASTRAL_POSITION_UPLOAD_STEPS } from '@/modules/cadastralPosition/entities/cadastralPosition.enum';
import { ParamsToUpload } from '@/modules/cadastralPosition/entities/cadastralPosition.interface';
import { CADASTRAL_POSITION_FORM_DATA } from '@/modules/cadastralPosition/entities/cadastralPosition.const';

export interface FormDataOptions {
  skipRequiredValidations: string[];
}

interface UseCadastralDataMatchingUpload {
  setCurrentStep: (step: CADASTRAL_POSITION_UPLOAD_STEPS) => void;
  setErrors: (errors: MovesValidationError) => void;
}

const filesService = new FilesService();

const useCadastralDataMatchingUpload = ({
  setCurrentStep,
  setErrors,
}: UseCadastralDataMatchingUpload) => {
  const { company } = useCompany();
  const [formValues, setFormValues] = useState<ParamsToUpload>({
    ...CADASTRAL_POSITION_FORM_DATA,
    companyId: company.id,
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [showDialogConfirmBeneficiaryWithoutCPF, setShowDialogConfirmBeneficiaryWithoutCPF] =
    useState(false);
  const { toast } = ToastStatusGlobal();

  const getFormDataValues = async (options?: FormDataOptions): Promise<FormData> => {
    const formData = new FormData();

    if (!formValues.files) {
      return formData;
    }

    formData.append('files', formValues.files);
    formData.append('negotiationId', formValues.negotiationId);

    if (options?.skipRequiredValidations) {
      options.skipRequiredValidations.forEach((skipRequiredValidation) => {
        formData.append('skipRequiredValidations', skipRequiredValidation);
      });
    }

    return formData;
  };

  const handleError = () => {
    toast('Puxa!', 'É necessário adicionar um arquivo para fazer o upload.', 'warning');
  };

  const handleSubmit = async (options?: FormDataOptions) => {
    setLoading(true);

    try {
      const formData = await getFormDataValues(options);
      await filesService.sendCadastralPosition(formData);
      toast('Feito!', 'O arquivo foi enviado.', 'success');
      setCurrentStep(CADASTRAL_POSITION_UPLOAD_STEPS.SUCCESS);
    } catch (error) {
      console.error(error);

      const errorResponse = get(error, 'response.data', movesValidationErrorInitialState);
      if (errorResponse.details?.length) {
        setErrors(errorResponse);
      }

      if (errorResponse.skippable) {
        setShowDialogConfirmBeneficiaryWithoutCPF(errorResponse.skippable);
        return;
      }

      if (errorResponse.details?.length) {
        setCurrentStep(CADASTRAL_POSITION_UPLOAD_STEPS.ERROR);
        setFormValues(CADASTRAL_POSITION_FORM_DATA);
      }

      const messageError = getMessageError({
        error,
        messageDefault: 'Falha ao fazer upload do arquivo!',
      });
      toast('Oops!', messageError, 'error');
    } finally {
      setLoading(false);
    }
  };

  return {
    formValues,
    loading,
    setFormValues,
    handleSubmit,
    handleError,
    showDialogConfirmBeneficiaryWithoutCPF,
    setShowDialogConfirmBeneficiaryWithoutCPF,
  };
};

export default useCadastralDataMatchingUpload;
