import { Color } from 'vkit/lib/shared/types';

export enum ADDITIONAL_SERVICES_STATUS {
  ACTIVE = 'active',
  CANCELLED = 'cancelled',
  EXPIRED = 'expired',
}

export const ADDITIONAL_SERVICES_STATUS_ALIAS_TRANSLATED = {
  active: 'Ativo',
  cancelled: 'Cancelado',
  expired: 'Expirado',
};

export const ADDITIONAL_SERVICES_STATUS_COLOR: Record<ADDITIONAL_SERVICES_STATUS, Color> = {
  [ADDITIONAL_SERVICES_STATUS.ACTIVE]: 'success',
  [ADDITIONAL_SERVICES_STATUS.CANCELLED]: 'danger',
  [ADDITIONAL_SERVICES_STATUS.EXPIRED]: 'warning',
};
