import React, { ReactNode } from 'react';
import { Grid } from 'vkit/lib/context';

export interface SlotsProps {
  left: ReactNode;
  center: ReactNode;
  right: ReactNode;
}

const Slots: React.FC<Partial<SlotsProps>> = (
  {
    left,
    center,
    right,
  }
) => {
  return (
    <Grid gap={12} stretch>
      <Grid padding='12px 0 12px 12px' growing>
        {left}
      </Grid>
      <Grid padding='12px 0' alignContent='center' growing>
        {center}
      </Grid>
      <Grid padding='12px 12px 12px 0' alignContent='right' growing>
        {right}
      </Grid>
    </Grid>
  );
};

export default Slots;
