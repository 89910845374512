import noImage from '@/assets/images/no-camera.png';
import TableLineCellChip from '@/components/dataGrid/components/tableLineCellChip/tableLineCellChip';
import TableLineCellText from '@/components/dataGrid/components/tableLineCellText/tableLineCellText';
import { DataGridCell, DataGridColumn } from '@/components/dataGrid/entities/types';
import React from 'react';
import { Image } from 'vkit/lib/components';

type TableLineCellDataProps<Data, Filter> = DataGridCell<Data, Filter> & {
  data: Data;
  align?: DataGridColumn<Data, Filter>['align'];
};

const TableLineCellData = function <Data, Filter>({
  data,
  field,
  align,
  formatData,
  type,
  textParams,
  chipParams,
  imageParams,
  translate,
}: TableLineCellDataProps<Data, Filter>) {
  if (type === 'image' && imageParams) {
    return <Image type='rounded' imageDefaultUrl={noImage} {...imageParams?.(data)} />;
  }

  if (type === 'chip') {
    return (
      <TableLineCellChip
        data={data}
        field={String(field)}
        align={align}
        translate={translate}
        {...chipParams}
      />
    );
  }

  return (
    <TableLineCellText
      data={data}
      field={String(field)}
      align={align}
      type={formatData}
      translate={translate}
      {...textParams?.(data)}
    />
  );
};

export default TableLineCellData;
