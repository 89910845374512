import DataGrid from '@/components/dataGrid/dataGrid';
import useFileDownload from '@/modules/files/hooks/useFileDownload/useFileDownload';
import { SelectType } from '@/modules/move/components';
import Status from '@/modules/move/components/status/Status';
import {
  MOVE_LIST_INITIAL_PAGE,
  MOVE_LIST_PAGE_SIZE_DEFAUlT,
  MOVE_TYPE_COLLECTION,
  MoveParams,
  MoveReponseNormalized,
} from '@/modules/move/entities';
import { MoveService } from '@/modules/move/services';
import ICONS from '@/shareds/constants/icons';
import React from 'react';

const List: React.FC = () => {
  const { downloadFile, loading: loadingDownload } = useFileDownload();

  return (
    <DataGrid
      initialFilterValue={() =>
        ({
          type: MOVE_TYPE_COLLECTION,
          page: MOVE_LIST_INITIAL_PAGE,
          pageSize: MOVE_LIST_PAGE_SIZE_DEFAUlT,
          sort: '-uploadedAt',
        }) as MoveParams
      }
      source={(filters: MoveParams, companyId) =>
        new MoveService().getMoves<MoveReponseNormalized>({ ...filters, companyId })
      }
      filters={[
        {
          label: 'Buacar',
          key: 'q',
        },
        {
          render: ({ type }, onChange) => (
            <SelectType value={type} setValue={(type) => onChange({ type })} />
          ),
        },
        {
          label: 'Data',
          key: 'createdAtMin',
          type: 'date',
        },
      ]}
      columns={[
        {
          headerName: 'Nome do arquivo',
          field: 'name',
          sortable: true,
        },
        {
          headerName: 'Criado por',
          field: 'user.name',
        },
        {
          headerName: 'Tipo',
          field: 'type',
        },
        {
          headerName: 'Fornecedor',
          field: 'provider',
        },
        {
          headerName: 'Status',
          render: ({ status }) => <Status label={status} />,
        },
        {
          headerName: 'Criado em',
          field: 'uploadedAt',
          formatData: 'dateTime',
          sortable: true,
        },
      ]}
      actionsByItem={[
        {
          redirectToDetail: ({ id }, companyId) =>
            `/companies/${companyId}/beneficiaries/moves/${id}/details`,
        },
        {
          label: 'Baixar arquivo',
          icon: ICONS.DOWNLOAD,
          onClick: ({ id }) => downloadFile(id),
          loading: loadingDownload,
        },
      ]}
    />
  );
};

export default List;
