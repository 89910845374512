import TableLineCellData from '@/components/dataGrid/components/tableLineCellData/tableLineCellData';
import { DataGridColumn } from '@/components/dataGrid/entities/types';
import React from 'react';
import { Grid, Tooltip } from 'vkit/lib/context';

interface TableLineCellProps<Data, Filter>
  extends Omit<DataGridColumn<Data, Filter>, 'headerName'> {
  data: Data;
}

const TableLineCell = function <Data, Filter>({
  data,
  field,
  align = 'left',
  sup,
  sub,
  render,
  description,
  formatData,
  type,
  translate,
  textParams,
  imageParams,
  chipParams,
}: TableLineCellProps<Data, Filter>) {
  return (
    <Tooltip title={description} align={align}>
      <Grid column gap={2}>
        {sup?.field ? (
          <TableLineCellData
            data={data}
            align={align}
            translate={sup.translate}
            textParams={() => ({
              size: 'small',
              ...sup.textParams?.(data),
            })}
            {...sup}
          />
        ) : (
          sup?.render?.(data)
        )}

        {!render ? (
          <TableLineCellData
            data={data}
            field={field}
            align={align}
            formatData={formatData}
            type={type}
            translate={translate}
            textParams={() => ({
              size: 'medium',
              ...textParams?.(data),
            })}
            chipParams={chipParams}
            imageParams={() => ({
              size: 'small',
              ...imageParams?.(data),
            })}
          />
        ) : (
          render?.(data)
        )}

        {sub?.field ? (
          <TableLineCellData
            data={data}
            align={align}
            translate={sub.translate}
            textParams={() => ({
              size: 'small',
              ...sub.textParams?.(data),
            })}
            {...sub}
          />
        ) : (
          sub?.render?.(data)
        )}
      </Grid>
    </Tooltip>
  );
};

export default TableLineCell;
