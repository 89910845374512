import { useCallback, useEffect, useState } from 'react';

import providersService, { Provider } from '@/services/negotiations/endpoints/providers';
import useCompany from '@/shared/hooks/useCompany/useCompany';
import { dispatchToast } from '@/utils/tryOrCatchMessageError';
import { BENEFIT_BASE_IDS_FOR_MOVE } from '@/modules/benefit/entities';
import NegotiationService from '../../../../modules/negotiation/services/negotiationService';

type SelectableProviderList = {
  text: string;
  value: string;
};

interface IUseProviders {
  selectableProviderList: SelectableProviderList[];
  getProvider: (providerId: string) => Provider | null;
  loading: boolean;
}

const negotiationService = new NegotiationService();

const useProviders = (): IUseProviders => {
  const { company } = useCompany();

  const [list, setList] = useState<Provider[]>([]);
  const [selectableProviderList, setProvidersList] = useState<SelectableProviderList[]>([]);
  const [loading, setLoading] = useState(false);

  const loadContractsActive = useCallback(async () => {
    if (!company.id) {
      return;
    }

    setLoading(true);
    try {
      return await negotiationService.getContractsActive(company.id, {
        'negotiations.costCenter.companyId': company.id,
        benefitBaseId: BENEFIT_BASE_IDS_FOR_MOVE,
      });
    } catch (e) {
      console.error(e);

      dispatchToast({
        text: 'Erro ao carregar o negociações da empresa.',
      });
    } finally {
      setLoading(false);
    }
  }, [company.id]);

  const getProvidersList = useCallback(
    async (companyId: string): Promise<void> => {
      setList([]);
      setProvidersList([]);

      const contracts = await loadContractsActive();

      if (!contracts) {
        return;
      }
      const contractsIdList = contracts.map((contract: { id: string }) => {
        return contract.id;
      });

      try {
        const providers = await providersService.list({
          'products.negotiations.costCenter.companyId': companyId,
          'products.negotiations.contractId': contractsIdList,
        });
        const selectable = providers.map((provider: Provider) => {
          return {
            text: provider.name,
            value: provider.id,
          };
        });
        setList(providers);
        setProvidersList(selectable);
      } catch (e) {
        console.error(e);
      }
    },
    [loadContractsActive],
  );

  const getProvider = (providerId: string): Provider | null => {
    const provider = list.find((origin: Provider) => origin.id === providerId);
    if (provider) {
      return provider;
    }
    return null;
  };

  useEffect(() => {
    if (!company.id) {
      return;
    }
    getProvidersList(company.id);
  }, [getProvidersList, company.id]);

  return {
    selectableProviderList,
    getProvider,
    loading,
  };
};

export default useProviders;
