import { Translate } from '@/shared/entities/types/translate';
import React, { useMemo } from 'react';
import { Select } from 'vkit/lib/components';
import SelectType from 'vkit/lib/components/webform/select/types/select.type';

export interface SelectTranslateProps extends SelectType {
  value: string;
  translate: Translate;
}

const SelectTranslate: React.FC<SelectTranslateProps> = (
  {
    value,
    translate,
    ...params
  },
) => {
  const data = useMemo(() => {
    return Object.entries(translate).map(([value, { text }]) => ({
      text,
      value,
    }))
  }, [translate]);

  return (
    <Select value={value} data={data} {...params} />
  );
};

export default SelectTranslate;
