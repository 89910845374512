import { ButtonGoBack } from '@/components';
import React from 'react';
import { Icon, Image, Text } from 'vkit/lib/components';
import { Grid, Row } from 'vkit/lib/context';

export interface HeaderProps {
  title?: string;
  icon?: string;
  iconSrc?: string;
  hasGoBackAction?: boolean;
}

const Header: React.FC<HeaderProps> = ({ title, icon, iconSrc, hasGoBackAction, children }) => {
  return (
    <Row style={{ padding: 0 }}>
      <Grid alignContent='justify' alignItems='center'>
        <Grid gap={4}>
          {icon && <Icon key={icon} name={icon} color='colorDefault' />}
          {iconSrc && <Image key={iconSrc} alt={title || ''} src={iconSrc} size='minor' />}
          {title && <Text color='colorDefault' value={title || ''} title />}
        </Grid>

        <Grid gap={16}>
          {hasGoBackAction && (
            <Grid alignContent='right'>
              <ButtonGoBack />
            </Grid>
          )}

          {children}
        </Grid>
      </Grid>
    </Row>
  );
};

export default Header;
