import DataGrid from '@/components/dataGrid/dataGrid';
import {
  CompanyGroupListResponse,
  ParamsToFilterCompanyGroups,
} from '@/modules/companyGroup/entities/companyGroup.interface';
import React from 'react';
import NegotiationsService from '@/shared/services/negotiationsService';

const List: React.FC = () => {
  return (
    <DataGrid
      source={(filters) =>
        new NegotiationsService().getCompanyGroups<
          CompanyGroupListResponse,
          ParamsToFilterCompanyGroups
        >(filters)
      }
      filters={[
        {
          label: 'Buscar',
          key: 'q',
          width: 100,
        },
      ]}
      columns={[
        {
          headerName: 'Nome',
          field: 'name',
        },
        {
          headerName: 'Criado em',
          field: 'createdAt',
          formatData: 'dateTime',
        },
      ]}
      actionsByItem={[
        {
          redirectToDetail: ({ id }) => `/company-groups/${id}/details`,
        },
      ]}
    />
  );
};

export default List;
