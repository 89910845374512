import DataGrid from '@/components/dataGrid/dataGrid';
import Header from '@/core/components/header/header';
import { SelectReasonAndSubReason } from '@/modules/attendance/components';
import { AttendanceService } from '@/modules/attendance/services';

import { useHistoryLocation, useHistoryNavigator } from '@/navigation';
import useCompany from '@/shared/hooks/useCompany/useCompany';
import ICONS from '@/shareds/constants/icons';
import React from 'react';

import { Button, Chip } from 'vkit/lib/components';
import { Grid } from 'vkit/lib/context';

import {
  AttendanceFilter,
  AttendanceResponseNormalized,
  FIELDS,
  STATUS_COLOR,
  statusOptions,
} from '../../entities';

const List: React.FC = () => {
  const { company } = useCompany();

  const navigate = useHistoryNavigator();
  const { pathname } = useHistoryLocation();

  return (
    <>
      <Header title='Atendimentos' icon={ICONS.ATTENDANCE}>
        <Button
          onClick={() => navigate.push(`${pathname}/actions/create`)}
          label='Solicitar atendimento'
          color='colorDefault'
          icon={ICONS.ATTENDANCE}
        />
      </Header>

      <DataGrid
        source={(filters: Partial<AttendanceFilter>) =>
          new AttendanceService().getAttendances<AttendanceResponseNormalized>(
            company.cnpj,
            filters,
          )
        }
        filters={[
          {
            label: 'Assunto',
            key: 'subject',
          },
          {
            width: 66.66,
            render: (values, onChange) => (
              <Grid gap={16} growing>
                <SelectReasonAndSubReason
                  key={FIELDS.REASON_SUBREASON}
                  values={values}
                  onChangeSelect={(key, value) => onChange({ [key]: value })}
                  small
                  showLabel
                />
              </Grid>
            ),
          },
          {
            label: 'Status',
            key: 'status',
            extra: true,
            type: 'select',
            data: statusOptions,
          },
          {
            label: 'Data início',
            extra: true,
            key: 'createdAtMin',
            type: 'date',
          },
          {
            label: 'Data final',
            extra: true,
            key: 'createdAtMax',
            type: 'date',
          },
        ]}
        columns={[
          {
            headerName: 'Assunto',
            field: 'subject',
          },
          {
            headerName: 'Criado em',
            field: 'createdAt',
          },
          {
            headerName: 'Status',
            type: 'chip',
            render: ({ status }) => (
              <Chip size='small' label={status} rounded color={STATUS_COLOR[status]} />
            ),
          },
          {
            headerName: 'Previsão',
            field: 'forecast',
          },
          {
            headerName: 'Motivo',
            field: 'reason',
          },
        ]}
        actionsByItem={[
          {
            redirectToDetail: ({ id }, companyId) => `/companies/${companyId}/attendances/${id}`,
          },
        ]}
      />
    </>
  );
};

export default List;
