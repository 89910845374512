import { fieldByType } from '@/components/dataGrid/entities/constants';
import { Translate } from '@/shared/entities/types/translate';
import React, { ReactNode } from 'react';
import { Grid } from 'vkit/lib/context';

type FieldType<Field extends Function> = Field extends (args: infer A) => any ? A : never;
type FieldTypeKeys = keyof typeof fieldByType;

export type FieldProps = FieldType<(typeof fieldByType)[FieldTypeKeys]> & {
  type?: FieldTypeKeys;
  render?: ReactNode;
  width?: number;
  translate?: Translate;
};

export type FilterFieldProps = FieldProps & {
  value: any;
  onChange: (params: any) => void;
  type?: FieldTypeKeys | never;
  render?: ReactNode | never;
};

const FilterField = function ({ type = 'text', width, render, ...params }: FilterFieldProps) {
  return <Grid growing>{render || fieldByType[type](params)}</Grid>;
};

export default FilterField;
