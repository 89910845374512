import { changeData } from '@/components/formValidate/utils/changeData';
import { doValidates } from '@/components/formValidate/utils/doValidates';
import { getValues } from '@/components/formValidate/utils/getValues';
import * as validation from '@/components/formValidate/validates';
import {
  useValidateType,
  validateNamesType,
} from '@/components/formValidate/types/formValidate.type';
import { ChangeEvent, useEffect, useState } from 'react';

export type ObjectType = { [key: string]: any };
export type TouchedType = { [key: string]: boolean };
type useFormValidateType = {
  fields: { [key: string]: (validateNamesType | Function)[] };
  fnCallback?: Function;
  onChangeForm?: Function;
  onError?: Function;
  onBeforeValidateForm?: Function;
  objectName?: string;
  resource?: ObjectType;
  resourceDefault?: ObjectType;
  externalErrors?: { [key: string]: string };
};

export interface IUseFormValidates {
  useValues: ObjectType;
  useValidate?: useValidateType;
  useErrors: ObjectType;
  onChange: (...item: any) => void;
  onBlur: (...item: any) => void;
  onValidateForm?: (...item: any) => Promise<boolean>;
  updateFieldErrors?: (...item: any) => void;
}

const useFormValidates = ({
  fields,
  fnCallback,
  onChangeForm,
  onError,
  onBeforeValidateForm,
  objectName,
  resource = {},
  resourceDefault = {},
  externalErrors,
}: useFormValidateType): IUseFormValidates => {
  const [useErrors, setErrors] = useState<ObjectType>({});
  const [useToucheds, setToucheds] = useState<TouchedType>({});
  const [useValidate, setValidate] = useState<useValidateType>({});

  const onChange = (keyOrObject: string | Record<string, unknown>, value: string) => {
    changeData({
      setToucheds,
      useToucheds,
      objectName,
      resource,
      onChangeForm,
      keyOrObject,
      value,
    });
  };

  const onBlur = async (name: string, value: string): Promise<void> => {
    await updateFieldErrors(name, value);
  };

  const updateFieldErrors = async (name: string, value: string): Promise<void> => {
    const { [name]: removedError, ...rest } = useErrors;

    const error = await doValidates(name, value, useValidate);

    setErrors({
      ...rest,
      ...(error && useToucheds[name] && { [name]: error }),
    });
  };

  const validateForm = async (values: any): Promise<{ errors: any; touched: any }> => {
    return Object.keys(fields).reduce(
      async (
        accPromises,
        key,
      ): Promise<{
        errors: ObjectType;
        touched: TouchedType;
      }> => {
        const acc = await accPromises;
        const value = values[key] as string;
        const newError = await doValidates(key, value, useValidate);
        const newTouched = { [key]: true };

        return {
          errors: {
            ...acc.errors,
            [key]: newError,
          },
          touched: {
            ...acc.touched,
            ...newTouched,
          },
        };
      },
      Promise.resolve({
        errors: { ...useErrors },
        touched: { ...useToucheds },
      }),
    );
  };

  const onValidateForm = async (evt: ChangeEvent<HTMLFormElement> | null): Promise<boolean> => {
    evt?.preventDefault();

    const valuesUpdated = getValues(resource, objectName);
    const valuesDefault = getValues(resourceDefault, objectName);
    const values = {
      ...valuesDefault,
      ...valuesUpdated,
    };

    const { errors, touched } = await validateForm(values);

    setErrors(errors);
    setToucheds(touched);

    onBeforeValidateForm?.(values);

    if (
      Object.values(errors).every((t) => !t) &&
      Object.values(touched).length === Object.values(fields).length
    ) {
      fnCallback?.(values);
      setErrors({});
      setToucheds({});
    } else {
      onError?.(errors);
    }

    return Object.values(errors).every((t) => !t);
  };

  useEffect(() => {
    const validations = {};

    for (const [fieldName, validateKeys] of Object.entries(fields)) {
      Object.assign(validations, {
        [fieldName]: validateKeys.map((validateKey) => {
          if (typeof validateKey === 'function') {
            return validateKey;
          }

          return validation[validateKey];
        }),
      });
    }

    setValidate(validations);
  }, [fields]);

  useEffect(() => {
    setErrors((internalErrors) => ({
      ...internalErrors,
      ...externalErrors,
    }));
  }, [externalErrors]);

  return {
    onBlur,
    onChange,
    useErrors,
    useValidate,
    useValues: (objectName ? resource?.[objectName] : resource) as ObjectType,
    onValidateForm,
    updateFieldErrors,
  };
};

export default useFormValidates;
