import { FormDataOptions } from '@/pages/moves/upload/hooks/useFormDataValidator';
import { get } from 'lodash';
import { useEffect, useMemo, useState } from 'react';

import movesValidationService, {
  MovesValidationError,
  movesValidationErrorInitialState,
} from '@/services/files/endpoints/moves';

import { TFormData } from '../types/FormData.type';

import { IProvider } from '../types/Provider.type';

import { MoveConfig } from '@/modules/move/entities';
import { USER_TYPE } from '@/modules/user';
import useCompany from '@/shared/hooks/useCompany/useCompany';
import { getMessageError } from '@/utils/getMessageError';
import { useFormDataValidator } from './';

type FormDataType = IProvider | string | string[] | boolean | null;

const initialFormData: TFormData = {
  scope: USER_TYPE.COMPANY,
  provider: null,
  movimentationType: null,
  templateDownloaded: false,
  files: null,
  attachment: null,
  layout: null,
};

const useFormData = (toast: Function) => {
  const { company } = useCompany();
  const [formValues, setFormValues] = useState<TFormData>(initialFormData);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [useError, setError] = useState<MovesValidationError>(movesValidationErrorInitialState);
  const [hasSubmitError, setSubmitError] = useState<boolean>(false);
  const [hasSubmitSuccess, setSubmitSucces] = useState<boolean>(false);
  const [companyMoveConfig, setCompanyMoveConfig] = useState<MoveConfig | null>(null);
  const [isSkipplableError, setIsSkipplableError] = useState(false);

  const { isValidFormData, getFormDataValues } = useFormDataValidator(
    formValues,
    company,
    companyMoveConfig,
  );

  const setData = (key: string, value: FormDataType): void => {
    setFormValues((current) => ({
      ...current,
      [key]: value,
    }));
  };

  const handleSubmit = async (options?: FormDataOptions): Promise<void> => {
    if (!isValidFormData) {
      return;
    }
    setLoading(true);
    setIsSkipplableError(false);
    try {
      const formData = await getFormDataValues(options);
      await movesValidationService.create(formData);
      toast('Feito!', 'O arquivo foi enviado.', 'success');
      setSubmitSucces(true);
    } catch (error) {
      console.error(error);

      const errorResponse = get(error, 'response.data', movesValidationErrorInitialState);
      if (errorResponse.details?.length) {
        setError(errorResponse);
      }

      if (errorResponse.skippable) {
        setIsSkipplableError(Boolean(errorResponse.skippable));
        return;
      }

      if (errorResponse.details?.length) {
        setSubmitError(true);
        setFormValues(initialFormData);
      }

      const messageError = getMessageError({
        error,
        messageDefault: 'Falha ao fazer upload do arquivo!',
      });
      toast('Oops!', messageError, 'error');
    } finally {
      setLoading(false);
    }
  };

  const resetSubmitError = (): void => {
    setSubmitError(false);
    setError(movesValidationErrorInitialState);
  };

  useEffect(() => {
    setFormValues(initialFormData);
    setError(movesValidationErrorInitialState);
  }, [company]);

  useEffect(() => {
    setFormValues((current) => ({
      ...initialFormData,
      provider: formValues.provider,
      layout: current.layout,
    }));
  }, [formValues.provider]);

  useEffect(() => {
    setFormValues({
      ...initialFormData,
      layout: formValues.layout,
    });
  }, [formValues.layout]);

  const isDisabledSubmit = useMemo((): boolean => {
    return !isValidFormData && !isLoading;
  }, [isLoading, isValidFormData]);

  return {
    setData,
    formValues,
    isDisabledSubmit,
    handleSubmit,
    isLoading,
    useError,
    hasSubmitError,
    resetSubmitError,
    hasSubmitSuccess,
    setCompanyMoveConfig,
    isSkipplableError,
  };
};

export default useFormData;
