import { Company } from '@/modules/company';
import { useCompanyPropagate } from '@/modules/company/hooks/useCompanyPropagate';
import ICONS from '@/shareds/constants/icons';
import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';
import { Text } from 'vkit/lib/components';

interface RedirectCompanyProps {
  company?: Company;
}

const RedirectCompanyLink: React.FC<RedirectCompanyProps> = ({ company }) => {
  const { propagateCompany } = useCompanyPropagate();
  const onClick = useCallback(
    (el) => {
      el.stopPropagation();
      if (!company?.id) return;
      const url = `/companies/${company.id}`;
      propagateCompany(company);
      location.href = url;
    },
    [company, propagateCompany],
  );

  return (
    <Link to={`/companies/${company?.id}`} onClick={onClick}>
      {company?.name && <Text value={company.name} icon={ICONS.LINK} iconDir='right' />}
    </Link>
  );
};

export default RedirectCompanyLink;
