import DataGrid from '@/components/dataGrid/dataGrid';
import Status from '@/modules/additionalServices/components/status/Status';
import AdditionalServicesService from '@/modules/additionalServices/services/AdditionalServicesService';
import { LIST_INITIAL_PAGE } from '@/shared/entities/constants/list';
import React from 'react';
import {
  AdditionalServicesNormalized,
  AdditionalServicesResponseNormalized,
} from '../entities/interfaces';

const AdditionalServices: React.FC = () => {
  return (
    <DataGrid
      initialFilterValue={() => ({
        page: LIST_INITIAL_PAGE,
        pageSize: 50,
      })}
      source={(filters, companyId) =>
        new AdditionalServicesService().getAdditionalServices<AdditionalServicesResponseNormalized>(
          companyId,
          filters,
        )
      }
      columns={[
        {
          type: 'image',
          width: '40px',
          imageParams: ({ provider }) => ({
            src: `https://s3.sa-east-1.amazonaws.com/app.vitta/provider-logos/${provider.cnpj}.png`,
          }),
        },
        {
          headerName: 'Fornecedor',
          field: 'provider.name',
        },
        {
          headerName: 'Benefício',
          field: 'sva.name',
        },
        {
          headerName: 'Status',
          render: ({ status }: AdditionalServicesNormalized) => <Status label={status} />,
        },
        {
          headerName: 'Ativação',
          field: 'validFrom',
        },
      ]}
      showPagination={false}
    />
  );
};

export default AdditionalServices;
