import React from 'react';
import { Chip } from 'vkit/lib/components';

import { MOVE_STATUS, MOVE_STATUS_COLOR, MOVE_STATUS_TRANSLATED } from '@/modules/move/entities';
export interface StatusProps {
  label: string;
}

const Status: React.FC<StatusProps> = ({ label }) => (
  <Chip
    size='small'
    label={label}
    color={MOVE_STATUS_COLOR[label]}
    rounded
    invertColor={label === MOVE_STATUS_TRANSLATED[MOVE_STATUS.NOT_INITIALIZED]}
  />
);

export default Status;
