import DataGrid from '@/components/dataGrid/dataGrid';
import { Company } from '@/modules/company';
import { COMPANY_COLUMNS } from '@/modules/company/entities/company.enum';
import useCompanyColumns from '@/modules/company/hooks/useCompanyColumns';
import React from 'react';

interface TableProps {
  columns?: COMPANY_COLUMNS[];
  companies: Company[];
  loading: boolean;
}

const CompanyTable: React.FC<TableProps> = ({ columns, companies, loading }) => {
  const { chosenColumn } = useCompanyColumns({
    columnsToChoose: columns,
  });

  return (
    <DataGrid
      loading={loading}
      columns={chosenColumn}
      source={() => Promise.resolve({ data: companies })}
      showPagination={false}
      doNotReadQueryString
    />
  );
};

export default CompanyTable;
