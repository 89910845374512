import { ChangeValue } from '@/components/formValidate/types/formValidate.type';
import { changeMany } from '@/components/formValidate/utils/changeManyValues';
import { changeOne } from '@/components/formValidate/utils/changeOneValue';

export interface ChangeData extends ChangeValue {
  keyOrObject: string | Record<string, unknown>;
  value: string;
}

export const changeData = ({
  setToucheds,
  useToucheds,
  objectName,
  resource,
  onChangeForm,
  keyOrObject,
  value,
}: ChangeData) => {
  if (typeof keyOrObject === 'string') {
    changeOne({
      setToucheds,
      useToucheds,
      objectName,
      resource,
      onChangeForm,
      name: keyOrObject,
      value,
    });
  } else {
    changeMany({
      setToucheds,
      useToucheds,
      objectName,
      resource,
      onChangeForm,
      item: keyOrObject,
    });
  }
};
