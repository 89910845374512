import DataGrid from '@/components/dataGrid/dataGrid';
import Header from '@/core/components/header/header';
import {
  CADASTRAL_POSITION_STATUS_COLOR,
  CADASTRAL_POSITION_STATUS_TRANSLATED,
} from '@/modules/cadastralPosition/entities/cadastralPosition.const';
import { MoveResponse } from '@/modules/move/entities';
import { MonitoringService } from '@/shared/modules/monitoring/services/monitoringService';
import React from 'react';

import ICONS from '@/shareds/constants/icons';
import { ParamsToFilter } from '@/modules/cadastralPosition/entities/cadastralPosition.interface';
import { Button, Chip } from 'vkit/lib/components';
import { useHistoryNavigator } from '@/navigation';

const List: React.FC = () => {
  const navigate = useHistoryNavigator();

  return (
    <>
      <Header title='Posição cadastral' icon={ICONS.CADASTRAL_POSITION}>
        <Button
          label='Enviar arquivo'
          iconColor='light'
          icon={ICONS.UPLOAD}
          onClick={() => navigate.push('/cadastral-positions/actions/upload')}
        />
      </Header>

      <DataGrid
        source={(filters) =>
          new MonitoringService().getFiles<MoveResponse, ParamsToFilter>(filters)
        }
        initialFilterValue={(companyId) => ({
          companyId: [companyId],
          sort: '-createdAt',
        })}
        filters={[
          {
            label: 'Buscar',
            key: 'q',
            width: 25,
          },
          {
            label: 'Status',
            key: 'status',
            width: 25,
            type: 'select',
            data: Object.entries(CADASTRAL_POSITION_STATUS_TRANSLATED).map(([key, value]) => ({
              text: value,
              value: key,
            })),
          },
          {
            label: 'Data mínima de criação',
            key: 'createdAtMin',
            type: 'date',
            width: 25,
          },
          {
            label: 'Data máxima de criação',
            key: 'createdAtMax',
            type: 'date',
            width: 25,
          },
          {
            label: 'Empresas',
            key: 'companyId',
            width: 100,
            type: 'selectCompanyChips',
            maxSelecteds: 10,
          },
        ]}
        columns={[
          {
            headerName: 'Nome do arquivo',
            field: 'name',
          },
          {
            headerName: 'Empresa',
            field: 'company.name',
            sub: {
              field: 'company.cnpj',
            },
          },
          {
            headerName: 'Status',
            render: ({ status }) => (
              <Chip
                rounded
                size='small'
                label={CADASTRAL_POSITION_STATUS_TRANSLATED[status]}
                color={CADASTRAL_POSITION_STATUS_COLOR[status]}
              />
            ),
          },
          {
            headerName: 'Data de upload',
            field: 'createdAt',
            formatData: 'dateTime',
          },
        ]}
        actionsByItem={[
          {
            redirectToDetail: ({ id }) => `/cadastral-positions/${id}/details`,
          },
        ]}
      />
    </>
  );
};

export default List;
