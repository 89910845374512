import { FormValidate, ShowHide, ToastStatusGlobal } from '@/components';
import { BodyForm } from '@/components/formValidate';
import { Negotiation } from '@/modules/negotiation';
import { useHistoryParams } from '@/navigation';
import AboutBeneficiaryForm from '@/pages/beneficiaries/createOrUpdate/forms/aboutBeneficiaryForm';
import AboutBeneficiaryHolderForm from '@/pages/beneficiaries/createOrUpdate/forms/aboutBeneficiaryHolderForm';
import AddressForm from '@/pages/beneficiaries/createOrUpdate/forms/addressForm';
import AttachmentForm from '@/pages/beneficiaries/createOrUpdate/forms/attachmentForm';
import BankAccountForm from '@/pages/beneficiaries/createOrUpdate/forms/bankAccountForm';
import ContractForm from '@/pages/beneficiaries/createOrUpdate/forms/contractForm';
import { checkContainsProvider } from '@/pages/beneficiaries/createOrUpdate/shareds/utils/checkContainsProvider';
import { BeneficiaryToMove } from '@/pages/beneficiaries/createOrUpdate/types/beneficiaryToMove';
import {
  PROVIDERS_REQUIRES_BANK,
  PROVIDERS_REQUIRES_CANVASSER,
} from '@/pages/beneficiaries/shareds/constants/providersRequires';
import { validateFiles } from '@/pages/beneficiaries/shareds/utils/validateFiles';
import {
  BENEFICIARY_OWNERSHIP,
  KINSHIP_DEGREE,
  MOVE_FILE_COLUMN_NAME,
} from '@/pages/moves/shareds/constants';
import { MOVES_TYPES } from '@/pages/moves/upload/enum/MovesType';
import React, { useCallback, useMemo, useState } from 'react';
import { Row } from 'vkit/lib/context';

interface BeneficiaryFormsProps {
  updateBeneficiaryToMove: (newData: BeneficiaryToMove) => void;
  showSelectExistingBeneficiary?: boolean;
  negotiationById?: { [key: string]: Negotiation };
  setAttachments: (attachments: File[]) => void;
  dataToMove: BeneficiaryToMove;
  onSubmit: () => void;
  loading: boolean;
  errorFields: { [key: string]: string };
}

const BeneficiaryForms: React.FC<BeneficiaryFormsProps> = ({
  showSelectExistingBeneficiary,
  negotiationById,
  updateBeneficiaryToMove,
  setAttachments,
  dataToMove,
  onSubmit,
  loading,
  errorFields,
}) => {
  const [disableFieldIfFilledIn, setDisableField] = useState<BeneficiaryToMove>({});
  const [dataToCopy, setDataToCopy] = useState<BeneficiaryToMove>({});
  const { beneficiaryId } = useHistoryParams();
  const { toast } = ToastStatusGlobal();

  const translatedActionName = useMemo(
    () => MOVES_TYPES[beneficiaryId ? 'update' : 'creation'].toLowerCase(),
    [beneficiaryId],
  );

  const isDependent = useMemo(
    () => dataToMove[MOVE_FILE_COLUMN_NAME.TITULARIDADE] === BENEFICIARY_OWNERSHIP.DEPENDENT,
    [dataToMove],
  );

  const disableField = useCallback(
    (key: keyof BeneficiaryToMove, value: boolean) =>
      setDisableField((oldData) => ({
        ...oldData,
        [key]: value,
      })),
    [],
  );

  const requiredIf = (fieldKey: keyof BeneficiaryToMove, fieldValues: (string | boolean)[]) => {
    return (value: string) => {
      if (!value && fieldValues.includes(dataToMove[fieldKey]!)) {
        return 'Campo obrigatório';
      }

      return null;
    };
  };

  const checkCanvasserShow = () => {
    if (!negotiationById || !dataToMove.negotiationId) {
      return false;
    }

    return checkContainsProvider(
      negotiationById[dataToMove.negotiationId],
      PROVIDERS_REQUIRES_CANVASSER,
    );
  };

  const checkRequiredCanvasserShow = (value: string) => {
    if (!value && checkCanvasserShow()) {
      return 'Campo obrigatório';
    }
    return null;
  };

  const checkBankAccountShow = () => {
    if (!negotiationById || !dataToMove.negotiationId) {
      return null;
    }

    return checkContainsProvider(
      negotiationById[dataToMove.negotiationId],
      PROVIDERS_REQUIRES_BANK,
    );
  };

  const checkRequiredBankAccont = (value: string) => {
    if (!value && checkBankAccountShow() && !isDependent) {
      return 'Campo obrigatório';
    }
    return null;
  };

  const getFields = ({ useValues, useErrors, onBlur, onChange }: BodyForm) => (
    <Row
      style={{
        padding: 0,
        gap: 16,
      }}>
      <ShowHide
        displayNone={!!dataToMove.beneficiaryHolderId}
        visible={!!(negotiationById && showSelectExistingBeneficiary)}
        transition='slideToDown'>
        <AboutBeneficiaryHolderForm
          dataToMove={dataToMove}
          disableField={disableField}
          disableFieldIfFilledIn={disableFieldIfFilledIn}
          negotiationById={negotiationById!}
          updateBeneficiaryToMove={updateBeneficiaryToMove}
          setDataToCopy={setDataToCopy}
          onChange={onChange}
          onBlur={onBlur}
          useErrors={useErrors}
          useValues={useValues}
        />
      </ShowHide>

      <ContractForm
        isDependent={isDependent}
        negotiationById={negotiationById!}
        dataToCopy={isDependent ? dataToCopy : {}}
        useErrors={useErrors}
        useValues={useValues}
        onBlur={onBlur}
        updateBeneficiaryToMove={updateBeneficiaryToMove}
      />

      <AboutBeneficiaryForm
        isDependent={isDependent}
        useErrors={useErrors}
        useValues={useValues}
        onBlur={onBlur}
        onChange={onChange}
        isCanvasserShow={checkCanvasserShow()}
      />

      <AddressForm
        dataToCopy={isDependent ? dataToCopy : {}}
        useErrors={useErrors}
        useValues={useValues}
        onBlur={onBlur}
        onChange={onChange}
        updateBeneficiaryToMove={updateBeneficiaryToMove}
      />

      {!!checkBankAccountShow() && (
        <BankAccountForm
          onChange={onChange}
          onBlur={onBlur}
          useErrors={useErrors}
          useValues={useValues}
          isDependent={isDependent}
          dataToCopy={isDependent ? dataToCopy : {}}
          updateBeneficiaryToMove={updateBeneficiaryToMove}
          negotiationById={negotiationById}
        />
      )}

      <AttachmentForm
        onBlur={onBlur}
        onChange={onChange}
        useErrors={useErrors}
        useValues={useValues}
        setAttachments={setAttachments}
      />
    </Row>
  );

  return (
    <FormValidate
      errors={errorFields}
      resource={dataToMove}
      onChangeForm={updateBeneficiaryToMove}
      onSubmit={onSubmit}
      onError={() =>
        toast('Puxa!', 'Existem alguns campos com preenchimento irregular.', 'warning')
      }
      fields={{
        [MOVE_FILE_COLUMN_NAME.CODIGO_DO_CONTRATO]: ['required'],
        [MOVE_FILE_COLUMN_NAME.SUBFATURA_CENTRO_DE_CUSTO]: ['required'],
        [MOVE_FILE_COLUMN_NAME.NOME]: ['required'],
        [MOVE_FILE_COLUMN_NAME.DATA_DE_NASCIMENTO]: ['required'],
        [MOVE_FILE_COLUMN_NAME.MATRICULA]: ['required'],
        [MOVE_FILE_COLUMN_NAME.CPF]: [requiredIf('skipValidationCPF', [false])],
        [MOVE_FILE_COLUMN_NAME.CPF_DO_TITULAR]: [
          requiredIf(MOVE_FILE_COLUMN_NAME.TITULARIDADE, [BENEFICIARY_OWNERSHIP.DEPENDENT]),
        ],
        [MOVE_FILE_COLUMN_NAME.SEXO]: ['required'],
        [MOVE_FILE_COLUMN_NAME.ESTADO_CIVIL]: ['required'],
        [MOVE_FILE_COLUMN_NAME.DATA_DE_INICIO_DA_VIGENCIA]: ['required'],
        [MOVE_FILE_COLUMN_NAME.NOME_DA_MAE]: ['required'],
        [MOVE_FILE_COLUMN_NAME.CEP]: ['required'],
        [MOVE_FILE_COLUMN_NAME.UF]: ['required'],
        [MOVE_FILE_COLUMN_NAME.LOGRADOURO]: ['required'],
        [MOVE_FILE_COLUMN_NAME.BAIRRO]: ['required'],
        [MOVE_FILE_COLUMN_NAME.CIDADE]: ['required'],
        [MOVE_FILE_COLUMN_NAME.COMPLEMENTO]: [],
        [MOVE_FILE_COLUMN_NAME.NUMERO]: ['required'],
        [MOVE_FILE_COLUMN_NAME.NOME_DO_PLANO]: ['required'],
        [MOVE_FILE_COLUMN_NAME.EMAIL_DO_SEGURADO]: ['required', 'email'],
        [MOVE_FILE_COLUMN_NAME.GRAU_DE_PARENTESCO]: [
          requiredIf(MOVE_FILE_COLUMN_NAME.TITULARIDADE, [BENEFICIARY_OWNERSHIP.DEPENDENT]),
        ],
        [MOVE_FILE_COLUMN_NAME.CNS]: [],
        [MOVE_FILE_COLUMN_NAME.DATA_DE_ADMISSAO]: [
          requiredIf(MOVE_FILE_COLUMN_NAME.TITULARIDADE, [BENEFICIARY_OWNERSHIP.HOLDER]),
        ],
        [MOVE_FILE_COLUMN_NAME.CODIGO_DO_ANGARIADOR]: [checkRequiredCanvasserShow],
        [MOVE_FILE_COLUMN_NAME.DATA_DA_ANGARIACAO]: [checkRequiredCanvasserShow],
        [MOVE_FILE_COLUMN_NAME.VINCULO_COM_A_EMPRESA]: ['required'],
        [MOVE_FILE_COLUMN_NAME.DATA_DE_CASAMENTO]: [],
        [MOVE_FILE_COLUMN_NAME.CODIGO_DA_ELEGIBILIDADE]: [
          requiredIf(MOVE_FILE_COLUMN_NAME.GRAU_DE_PARENTESCO, [KINSHIP_DEGREE.SPOUSE]),
        ],
        [MOVE_FILE_COLUMN_NAME.TIPO_DE_CONTA_BANCARIA]: [checkRequiredBankAccont],
        [MOVE_FILE_COLUMN_NAME.BANCO]: [checkRequiredBankAccont],
        [MOVE_FILE_COLUMN_NAME.AGENCIA]: [checkRequiredBankAccont],
        [MOVE_FILE_COLUMN_NAME.DV_DA_AGENCIA]: [checkRequiredBankAccont],
        [MOVE_FILE_COLUMN_NAME.CONTA]: [checkRequiredBankAccont],
        [MOVE_FILE_COLUMN_NAME.DV_DA_CONTA]: [checkRequiredBankAccont],

        beneficiaryId: [],
        beneficiaryHolderId: [
          requiredIf(MOVE_FILE_COLUMN_NAME.TITULARIDADE, [BENEFICIARY_OWNERSHIP.DEPENDENT]),
        ],
        contractId: ['required'],
        attachments: [validateFiles],
        skipValidationCPF: [],
      }}
      body={getFields}
      buttons={{
        submit: {
          text: `Solicitar ${translatedActionName} do beneficiário`,
          loading,
        },
      }}
    />
  );
};

export default BeneficiaryForms;
