import ShowHide from '@/components/showHide';
import MetadataTable from '@/modules/move/components/dialogConfirmSkipErrors/metadataTable';
import SkipErrorConfirmation from '@/modules/move/components/dialogConfirmSkipErrors/skipErrorConfirmation';
import { MovesValidationErrorDetail } from '@/services/files/endpoints/moves';
import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { Button, Dialog } from 'vkit/lib/components';
import { Grid, Row } from 'vkit/lib/context';

interface DialogConfirmSkipErrorsProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  errorDetails: MovesValidationErrorDetail[];
  messageConfirm: string;
  messageOnMetadataTable?: string;
  titleOnMetadataTable?: string;
  metadataKeyOnErrorDetails?: string;
  metadataHeaderNameOnErrorDetails?: string;
  showMetadataTable?: boolean;
  onConfirm: () => void;
}

const DialogConfirmSkipErrors: React.FC<DialogConfirmSkipErrorsProps> = ({
  open,
  setOpen,
  messageConfirm,
  onConfirm,
  titleOnMetadataTable,
  messageOnMetadataTable,
  errorDetails,
  metadataKeyOnErrorDetails,
  metadataHeaderNameOnErrorDetails,
}) => {
  const useRefDialog = useRef({ open: () => {}, dismiss: () => {} });
  const [page, setPage] = useState<'message' | 'metadataTable'>('message');

  useEffect(() => {
    if (open !== undefined) {
      open ? useRefDialog.current.open() : useRefDialog.current.dismiss();
    }
  }, [open]);

  return (
    <Dialog
      shadow
      outlined
      rounded
      instanceEmitter={useRefDialog.current}
      elevation={2}
      size='larger'
      body={
        <Grid padding={32} column>
          <ShowHide transition='fade' visible={page === 'message'}>
            <Grid column gap={32} alignItems='center'>
              <SkipErrorConfirmation
                messageConfirm={messageConfirm}
                onConfirm={onConfirm}
                setOpen={setOpen}
              />

              <Button
                solo
                label={`Exibir ${titleOnMetadataTable?.toLowerCase()}`}
                onClick={() => setPage('metadataTable')}
                testId='show_metadata_table'
              />
            </Grid>
          </ShowHide>

          {metadataKeyOnErrorDetails && metadataHeaderNameOnErrorDetails && (
            <ShowHide transition='fade' visible={page === 'metadataTable'}>
              <Grid column gap={32} alignItems='center' alignContent='center'>
                <MetadataTable
                  title={titleOnMetadataTable}
                  message={messageOnMetadataTable}
                  keyOnErrorMetadata={metadataKeyOnErrorDetails}
                  headerNameTable={metadataHeaderNameOnErrorDetails}
                  errorDetails={errorDetails}
                />

                <Row
                  style={{ minWidth: '300px', padding: 0, alignItems: 'center', margin: 'auto' }}>
                  <Button
                    label='Voltar'
                    onClick={() => setPage('message')}
                    size='larger'
                    full
                    testId='show_message'
                  />
                </Row>
              </Grid>
            </ShowHide>
          )}
        </Grid>
      }
    />
  );
};

export default DialogConfirmSkipErrors;
