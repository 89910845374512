import { DataGridColumn } from '@/components/dataGrid/entities/types';

import { COMPANY_COLUMNS } from '@/modules/company/entities/company.enum';
import { Company, ParamsToFilterCompanies } from '@/modules/company/entities/company.type';
import { useMemo } from 'react';
import RedirectCompanyLink from '../components/redirectCompanyLink/redirectCompanyLink';

interface UseCompanyColumns {
  columnsToChoose?: COMPANY_COLUMNS[];
}

const useCompanyColumns = ({ columnsToChoose }: UseCompanyColumns) => {
  const AVALIBLE_COLUMNS: Record<
    COMPANY_COLUMNS,
    DataGridColumn<Company, ParamsToFilterCompanies>
  > = useMemo(
    () => ({
      [COMPANY_COLUMNS.NAME]: {
        headerName: 'Nome',
        render: (company) => <RedirectCompanyLink company={company} />,
      },
      [COMPANY_COLUMNS.SUPPORT_NAME]: {
        headerName: 'Executivo de relacionamento',
        field: 'supportName',
      },
      [COMPANY_COLUMNS.CNPJ]: {
        headerName: 'CNPJ',
        field: 'cnpj',
        formatData: 'cnpj',
      },
      [COMPANY_COLUMNS.CREATED_AT]: {
        headerName: 'Criada em',
        field: 'createdAt',
        minWidth: '180px',
        formatData: 'dateTime',
      },
    }),
    [],
  );

  const chosenColumn = useMemo(() => {
    if (!columnsToChoose) {
      return Object.values(AVALIBLE_COLUMNS).map((column) => column);
    }
    return columnsToChoose.map((column) => AVALIBLE_COLUMNS[column]);
  }, [columnsToChoose, AVALIBLE_COLUMNS]);

  return {
    chosenColumn,
  };
};

export default useCompanyColumns;
