import { Translate } from '@/shared/entities/types/translate';
import formatText, { FormatData } from '@/utils/formatText';
import { get } from 'lodash';
import React, { useMemo } from 'react';
import { Text } from 'vkit/lib/components';
import { Grid } from 'vkit/lib/context';
import TextType from 'vkit/lib/shared/components/text/types/text.type';

interface TableLineCellTextProps<Data> extends Omit<TextType, 'value'> {
  data: Data;
  field: string;
  align?: 'center' | 'left' | 'right';
  type?: FormatData;
  translate?: Translate;
}

const TableLineCellText = function<T>(
  {
    data,
    field,
    align,
    type = 'text',
    translate,
    ...textParams
  }:TableLineCellTextProps<T>
) {
  const value = useMemo(() => get(data, field), [data, field]);

  return value ? (
    <Grid stretch alignContent={align}>
      {
        translate ? (
          <Text value={formatText(translate[value].text, type)} color={translate[value].color || 'light'} {...textParams} />
        ) : (
          <Text value={formatText(value, type)} {...textParams} />
        )
      }
    </Grid>
  ) : null;
};

export default TableLineCellText;
