import BoxInfo from '@/components/boxInfo';
import DataGrid from '@/components/dataGrid/dataGrid';
import EmptyMessage from '@/components/emptyMessage';
import { useCurrentUser } from '@/modules/profile';
import {
  USER_PERMISSIONS,
  USER_STATUS,
  USER_STATUS_TRANSLATED,
  USER_TYPE,
  UserSearchParams,
} from '@/modules/user';
import ChipStatus from '@/modules/user/components/chipStatus/chipStatus';
import { UserService } from '@/modules/user/services';
import React, { useMemo } from 'react';

interface TableAndFilterUserProps {
  userType: USER_TYPE;
}

const TableAndFilterUser: React.FC<TableAndFilterUserProps> = ({ userType }) => {
  const { checkUserHasPermission } = useCurrentUser();
  const hasPermission = useMemo(() => {
    const permission =
      userType === USER_TYPE.BROKER
        ? USER_PERMISSIONS.BROKER_USER_READ
        : USER_PERMISSIONS.COMPANY_USER_READ;

    return checkUserHasPermission(permission);
  }, [checkUserHasPermission, userType]);

  return hasPermission ? (
    <DataGrid
      source={(filters: Partial<UserSearchParams>, companyId) => {
        return new UserService().getUsers({
          ...filters,
          scopeId: userType === USER_TYPE.BROKER ? process.env.REACT_APP_BROKER_ID : companyId,
          type: userType,
          withDeleted: !filters.status || filters.status === USER_STATUS.INACTIVATED,
        });
      }}
      filters={[
        {
          label: 'Nome ou e-mail',
          key: 'q',
          width: 75,
        },
        {
          label: 'Status',
          type: 'select',
          data: Object.entries(USER_STATUS_TRANSLATED).map(([value, text]) => ({
            value,
            text,
          })),
          key: 'status',
          width: 25,
        },
      ]}
      columns={[
        {
          headerName: 'Nome',
          field: 'name',
        },
        {
          headerName: 'E-mail',
          field: 'email',
        },
        {
          headerName: 'Status',
          render: ({ status }) => <ChipStatus status={status} />,
        },
        {
          headerName: 'Criado em',
          field: 'createdAt',
          formatData: 'date',
        },
      ]}
      actionsByItem={[
        {
          redirectToDetail: ({ id, type, status }, companyId) => {
            const route =
              type === USER_TYPE.BROKER
                ? `/administrators/${id}/details`
                : `/companies/${companyId}/users/${id}/details`;

            if (status === USER_STATUS.INACTIVATED) {
              return `${route}?userStatus=${USER_STATUS.INACTIVATED}`;
            }

            return route;
          },
        },
      ]}
    />
  ) : (
    <BoxInfo>
      <EmptyMessage
        title='Sem permissão'
        description='Não é possível exibir os registros de usuários'
      />
    </BoxInfo>
  );
};

export default TableAndFilterUser;
