import { ChangeValue } from '@/components/formValidate/types/formValidate.type';

export interface ChangeMany extends ChangeValue {
  item: Record<string, unknown>;
}

export const changeMany = ({
  setToucheds,
  useToucheds,
  objectName,
  resource,
  onChangeForm,
  item,
}: ChangeMany) => {
  const toucheds = Object.keys(item).reduce(
    (acc, key) => ({
      ...acc,
      [key]: true,
    }),
    useToucheds,
  );

  setToucheds(toucheds);

  let params: {};

  if (objectName) {
    const param = resource?.[objectName];

    params = {
      ...resource,
      [objectName]: {
        ...param,
        ...item,
      },
    };
  } else {
    params = {
      ...resource,
      ...item,
    };
  }

  onChangeForm?.(params);
};
