import { ShowHide, ToastStatusGlobal } from '@/components';
import { BENEFIT_BASE_IDS } from '@/modules/benefit/entities/benefit';
import { Negotiation } from '@/modules/negotiation';
import { useHistoryNavigator, useHistoryParams } from '@/navigation';
import ChooseOwnership from '@/pages/beneficiaries/createOrUpdate/components/chooseOwnership';
import BeneficiaryForms from '@/pages/beneficiaries/createOrUpdate/forms/beneficiaryForms';
import { beneficiaryToMoveFileDTO } from '@/pages/beneficiaries/createOrUpdate/shareds/utils/beneficiaryToMoveFileDTO';
import { BeneficiaryToMove } from '@/pages/beneficiaries/createOrUpdate/types/beneficiaryToMove';
import {
  BENEFICIARY_OWNERSHIP,
  COMPANY_LINK,
  MOVE_FILE_COLUMN_NAME,
  MOVE_FILE_TYPE,
} from '@/pages/moves/shareds/constants';
import { MOVES_TYPES } from '@/pages/moves/upload/enum/MovesType';
import { getErrorDescription } from '@/pages/moves/upload/utils/getErrorDescription';
import { mountFile } from '@/pages/moves/utils/mountFile';
import { beneficiariesService } from '@/services/beneficiaries';
import {
  Beneficiary,
  beneficiaryInitialState,
} from '@/services/beneficiaries/endpoints/beneficiaries';
import { movesValidationService } from '@/services/files';
import {
  MovesValidationError,
  MovesValidationErrorDetail,
  movesValidationErrorInitialState,
} from '@/services/files/endpoints/moves';
import { negotiationsService } from '@/services/negotiations/endpoints/negotiations';

import { findRoutePathByName } from '@/core/utils';
import { ROUTE_MOVES } from '@/modules/move/routes/moveRoute';
import useCompany from '@/shared/hooks/useCompany/useCompany';
import { ObjectType } from '@/shareds/types';
import { decodedJwtToken } from '@/utils/authentication';
import { getMessageError } from '@/utils/getMessageError';
import translateRulesValidator from '@/utils/translateRulesValidator';
import { tryOrCatchMessageError } from '@/utils/tryOrCatchMessageError';
import { get, isEmpty, keyBy } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { generatePath } from 'react-router-dom';
import { Row } from 'vkit/lib/context';
import useFilterNegotiationMove from '../../../modules/negotiation/hooks/useFilterNegotiationMove/useFilterNegotiationMove';

const { REACT_APP_MOVE_UNIT_MANUAL_PROVIDERS_ID = '' } = process.env;

const CreateOrUpdate: React.FC = () => {
  const { beneficiaryId, companyId } = useHistoryParams();
  const { company } = useCompany();
  const { toast } = ToastStatusGlobal();
  const { filterNegotiationActiveMove } = useFilterNegotiationMove();
  const navigate = useHistoryNavigator();

  const [loading, setLoading] = useState(false);
  const [dataToMove, setDataToMove] = useState<BeneficiaryToMove>({});
  const [negotiationById, setNegotiationById] = useState<Record<string, Negotiation>>({});
  const [attachments, setAttachments] = useState<File[]>([]);
  const [errorFields, setErrorFields] = useState<{ [key: string]: string }>({});
  const [beneficiary, setBeneficiary] = useState<Beneficiary>(beneficiaryInitialState);

  const moveSuccessRouteFound = findRoutePathByName(ROUTE_MOVES.UPLOAD_SUCCESS);

  const translatedActionName = useMemo(
    () => MOVES_TYPES[beneficiaryId ? 'update' : 'creation'].toLowerCase(),
    [beneficiaryId],
  );

  const unitMoveType = useMemo(
    () => (beneficiaryId ? MOVE_FILE_TYPE.UPDATE : MOVE_FILE_TYPE.CREATION),
    [beneficiaryId],
  );

  const isDependent = useMemo(
    () => dataToMove[MOVE_FILE_COLUMN_NAME.TITULARIDADE] === BENEFICIARY_OWNERSHIP.DEPENDENT,
    [dataToMove],
  );

  const updateBeneficiaryToMove = useCallback((newData: BeneficiaryToMove) => {
    if (isEmpty(newData)) {
      setDataToMove({});
      return;
    }

    setDataToMove((oldData) => ({
      ...oldData,
      ...newData,
    }));
  }, []);

  const loadBeneficiary = useCallback((beneficiaryIdToFound: string, companyIdToFound: string) => {
    tryOrCatchMessageError(
      async () => {
        const beneficiaryFound = (await beneficiariesService.find(
          beneficiaryIdToFound,
          companyIdToFound,
        )) as Beneficiary;
        setBeneficiary(beneficiaryFound);
      },
      {
        messageErrorDefault: 'Erro ao carregar o beneficiário.',
      },
    );
  }, []);

  const loadNegotiations = useCallback(
    async (companyIdToFoundNegotiation: string) => {
      tryOrCatchMessageError(
        async () => {
          const negotiationsFound = (await negotiationsService(companyIdToFoundNegotiation).listAll(
            {
              'costCenter.companyId': companyIdToFoundNegotiation,
              'product.benefit.baseId': [
                BENEFIT_BASE_IDS.DENTAL,
                BENEFIT_BASE_IDS.HEALTH,
                BENEFIT_BASE_IDS.MEDICATION,
              ],
            },
          )) as Negotiation[];

          const negotiationsFoundFiltered = filterNegotiationActiveMove(negotiationsFound);
          setNegotiationById(keyBy(negotiationsFoundFiltered, 'id'));
        },
        {
          messageErrorDefault: 'Erro ao carregar negociações.',
        },
      );
    },
    [filterNegotiationActiveMove],
  );

  const getFormDataValues = async () => {
    const formData = new FormData();
    const decodedToken = decodedJwtToken();
    const negotiation = negotiationById[dataToMove.negotiationId!];
    const movimentationType = beneficiaryId ? 'update' : 'creation';
    const sliptedProvidersId = REACT_APP_MOVE_UNIT_MANUAL_PROVIDERS_ID?.split(',');

    const sheet = mountFile([dataToMove], unitMoveType, dataToMove[MOVE_FILE_COLUMN_NAME.NOME]!);

    formData.append('userName', decodedToken.user.name);
    formData.append('userId', decodedToken.sub);
    formData.append('userEmail', decodedToken.user.email);
    formData.append('scope', 'company');
    formData.append('companyId', company.id);
    if (!sliptedProvidersId.includes(negotiation?.product.providerId)) {
      formData.append('providerId', negotiation?.product.providerId);
    }
    formData.append('movimentationType', movimentationType);
    formData.append('files', sheet);

    if (attachments?.length) {
      attachments.forEach((attachment) => formData.append('files', attachment));
    }

    if (dataToMove.skipValidationCPF) {
      formData.append('skipRequiredValidations', 'CPF');
    }

    return formData;
  };

  const setErrorsByField = (details: MovesValidationErrorDetail[]) => {
    const errors = details.reduce((acc: ObjectType, detail) => {
      const fieldName = detail.fields[0].replace(/^\d+\./, '') || '';
      const relatedParamsNegotiation = [
        MOVE_FILE_COLUMN_NAME.SUBFATURA_CENTRO_DE_CUSTO,
        MOVE_FILE_COLUMN_NAME.CODIGO_DO_CONTRATO,
      ];
      const errorDescription =
        translateRulesValidator(detail.rule, detail.details?.expected) ||
        getErrorDescription(detail);

      if (relatedParamsNegotiation.includes(fieldName as MOVE_FILE_COLUMN_NAME)) {
        return {
          ...acc,
          negotiationId: acc.negotiationId
            ? `${acc.negotiationId} | ${fieldName}: ${errorDescription}`
            : `${fieldName}: ${errorDescription}`,
        };
      }

      return {
        ...acc,
        [fieldName]: errorDescription,
      };
    }, {});

    setErrorFields(errors);
  };

  const save = async () => {
    setLoading(true);

    try {
      const formData = await getFormDataValues();
      await movesValidationService.create(formData);
      if (moveSuccessRouteFound) {
        navigate.push(generatePath(moveSuccessRouteFound, { companyId }));
      }
    } catch (error) {
      console.error(error);

      const { details } = get(
        error,
        'response.data',
        movesValidationErrorInitialState,
      ) as MovesValidationError;

      if (Array.isArray(details)) {
        setErrorsByField(details);
      }

      let messageError = 'Existem alguns campos com preenchimento irregular.';

      if (!Array.isArray(details) || !details?.length) {
        messageError = getMessageError({
          error,
          messageDefault: `Erro ao solicitar ${translatedActionName} do beneficiário.`,
        });
      }

      toast('Puxa!', messageError, 'warning');
    } finally {
      setLoading(false);
    }
  };

  const updateBeneficiary = useCallback(
    (negotiationId: string) => {
      const parsedBeneficiaryToMoveFile = beneficiaryToMoveFileDTO(
        beneficiary,
        negotiationById[negotiationId],
      );

      updateBeneficiaryToMove({
        ...parsedBeneficiaryToMoveFile,
        beneficiaryId: beneficiary.id,
        beneficiaryHolderId: isDependent ? beneficiary.beneficiaryHolder?.holderId : '',
        negotiationId: beneficiary.negotiationId,
      });
    },
    [beneficiary, isDependent, negotiationById, updateBeneficiaryToMove],
  );

  useEffect(() => {
    if (!company.id || !beneficiaryId) {
      return;
    }

    loadBeneficiary(beneficiaryId, company.id);
  }, [beneficiaryId, company.id, loadBeneficiary]);

  useEffect(() => {
    if (!company.id) {
      return;
    }

    loadNegotiations(company.id);
  }, [company.id, loadNegotiations]);

  useEffect(() => {
    const negotiationId = beneficiary.negotiationId;
    if (beneficiary.id && negotiationById[negotiationId]) {
      updateBeneficiary(negotiationId);
    }
  }, [beneficiary, isDependent, negotiationById, updateBeneficiary, updateBeneficiaryToMove]);

  useEffect(() => {
    if (isDependent) {
      setDataToMove((oldData) => ({
        ...oldData,
        [MOVE_FILE_COLUMN_NAME.VINCULO_COM_A_EMPRESA]: COMPANY_LINK.NO_LINK_IDENTIFICATION,
      }));
    } else {
      setDataToMove((oldData) => ({
        ...oldData,
        [MOVE_FILE_COLUMN_NAME.VINCULO_COM_A_EMPRESA]: undefined,
      }));
    }
  }, [isDependent]);

  return (
    <Row
      style={{
        padding: 0,
        gap: 16,
      }}>
      <ShowHide visible={!beneficiaryId}>
        <ChooseOwnership dataToMove={dataToMove} onChangeForm={updateBeneficiaryToMove} />
      </ShowHide>

      <ShowHide transition='slideToDown' visible={dataToMove[MOVE_FILE_COLUMN_NAME.TITULARIDADE]}>
        <BeneficiaryForms
          errorFields={errorFields}
          loading={loading}
          setAttachments={setAttachments}
          showSelectExistingBeneficiary={isDependent}
          negotiationById={negotiationById}
          dataToMove={dataToMove}
          updateBeneficiaryToMove={updateBeneficiaryToMove}
          onSubmit={save}
        />
      </ShowHide>
    </Row>
  );
};

export default CreateOrUpdate;
