import ICONS from '@/shareds/constants/icons';
import React, { Dispatch, SetStateAction } from 'react';
import IconBox from '@/components/iconBox';
import { Button, Text } from 'vkit/lib/components';
import { Grid } from 'vkit/lib/context';

export interface SkipErrorConfirmationProps {
  messageConfirm: string;
  onConfirm: () => void;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

const SkipErrorConfirmation: React.FC<SkipErrorConfirmationProps> = ({
  messageConfirm,
  onConfirm,
  setOpen,
}) => {
  return (
    <Grid column>
      <Grid margin='0 0 32px' alignContent='center' stretch>
        <IconBox icon={ICONS.ALERT} size={70} circle color='warning' />
      </Grid>

      <Grid margin='0 0 16px' alignContent='center' stretch>
        <Text value='Atenção:' title size='medium' color='colorDefault' />
      </Grid>

      <Grid margin='0 0 24px' alignContent='center' stretch>
        <Text value={messageConfirm} size='medium' textAlign='center' />
      </Grid>

      <Grid stretch gap={24} alignContent='center'>
        <Button
          size='larger'
          testId='go_back'
          onClick={() => setOpen(false)}
          label='Voltar'
          solo
          outlined
          full
        />
        <Button
          size='larger'
          testId='continue'
          onClick={onConfirm}
          label='Continuar'
          outlined
          full
        />
      </Grid>
    </Grid>
  );
};

export default SkipErrorConfirmation;
