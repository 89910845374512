import { ShowHide } from '@/components';
import DataGrid from '@/components/dataGrid/dataGrid';
import Header from '@/core/components/header/header';
import { COMPANY_CONFIG } from '@/modules/company/entities/company.enum';
import { MoveResponse } from '@/modules/move/entities';
import useCompany from '@/shared/hooks/useCompany/useCompany';
import { MonitoringService } from '@/shared/modules/monitoring/services/monitoringService';
import ICONS from '@/shareds/constants/icons';
import React, { useMemo } from 'react';
import { Chip, Text } from 'vkit/lib/components';

import {
  ButtonGoToUpload,
  CADASTRAL_DATA_MATCHING_STATUS,
  CADASTRAL_DATA_MATCHING_STATUS_COLOR,
  CADASTRAL_DATA_MATCHING_STATUS_TRANSLATED,
  CadastralDataMatchingParamsToFilter,
} from '@/modules/cadastralDataMatching';

const List: React.FC = () => {
  const { getFirstCompanyConfigByKey, getAllCompanyConfigsByKey } = useCompany();
  const layoutType = getFirstCompanyConfigByKey(COMPANY_CONFIG.CADASTRAL_DATA_MATCHING_LAYOUT);
  const fileTypes = getAllCompanyConfigsByKey(COMPANY_CONFIG.CADASTRAL_DATA_MATCHING_LAYOUT);
  const statusSelectOptions = useMemo(
    () =>
      Object.values(CADASTRAL_DATA_MATCHING_STATUS).map((statusKey) => ({
        text: CADASTRAL_DATA_MATCHING_STATUS_TRANSLATED[statusKey],
        value: statusKey,
      })),
    [],
  );

  return (
    <>
      <Header iconSrc={ICONS.CADASTRAL_DATA_MATCHING} title={'Bate Cadastral'}>
        <ButtonGoToUpload />
      </Header>

      <ShowHide visible={!layoutType} transition='slideToDown'>
        <Text
          rounded
          size='medium'
          applyBaseColor
          color='warning'
          iconColor='warning'
          padding='8px 12px'
          icon={ICONS.WARNING}
          value='Esta empresa não possui as configurações necessárias para visualização da lista de bates cadastrais.'
        />
      </ShowHide>

      <DataGrid
        source={(filters, companyId) =>
          new MonitoringService().getFiles<MoveResponse, CadastralDataMatchingParamsToFilter>({
            ...filters,
            companyId,
            type: fileTypes,
            sort: '-uploadedAt',
          })
        }
        columns={[
          {
            headerName: 'Nome do arquivo',
            field: 'name',
          },
          {
            headerName: 'Status',
            render: ({ status }) => (
              <Chip
                rounded
                size='small'
                label={CADASTRAL_DATA_MATCHING_STATUS_TRANSLATED[status]}
                color={CADASTRAL_DATA_MATCHING_STATUS_COLOR[status]}
              />
            ),
          },
          {
            headerName: 'Data da criação',
            field: 'createdAt',
            formatData: 'dateTime',
          },
        ]}
        filters={[
          {
            label: 'Buscar',
            key: 'q',
          },
          {
            label: 'Status',
            type: 'select',
            data: statusSelectOptions,
            key: 'status',
          },
          {
            label: 'Data de criação',
            type: 'date',
            key: 'createdAtMin',
          },
        ]}
        actionsByItem={[
          {
            redirectToDetail: ({ id }, companyId) =>
              `/companies/${companyId}/cadastral-data-matchings/${id}/details`,
          },
        ]}
      />
    </>
  );
};

export default List;
