import Header from '@/core/components/header/header';
import { useCurrentUser } from '@/modules/profile';
import { USER_PERMISSIONS, USER_TYPE } from '@/modules/user';
import ButtonGoToCreateUser from '@/modules/user/components/buttonGoToCreateUser/buttonGoToCreateUser';
import { ROUTE_USER } from '@/modules/user/routes';
import TableAndFilterUser from '@/modules/user/views/tableAndFilterUser/tableAndFilterUser';
import ICONS from '@/shareds/constants/icons';
import React from 'react';

const HomeUserCompany: React.FC = () => {
  const { checkUserHasPermission } = useCurrentUser();

  return (
    <>
      <Header title='Usuários' icon={ICONS.USER}>
        <ButtonGoToCreateUser
          routeIdToDetails={ROUTE_USER.COMPANY_USER_CREATE}
          disabledCreateAction={!checkUserHasPermission(USER_PERMISSIONS.COMPANY_USER_CREATE)}
        />
      </Header>
      <TableAndFilterUser userType={USER_TYPE.COMPANY} />
    </>
  );
};

export default HomeUserCompany;
