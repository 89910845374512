import { MOVE_LAYOUT_DEFAULT, MoveConfig } from '@/modules/move/entities';
import { Company } from '@/services/negotiations/endpoints/companies';
import { decodedJwtToken } from '@/utils/authentication';
import { useMemo } from 'react';

import { TFormData } from '../types/FormData.type';

export interface FormDataOptions {
  skipRequiredValidations: string[];
}

type TFormDataValidator = {
  hasProvider: boolean;
  hasMovimentationType: boolean;
  hasMovimentationFile: boolean;
  isValidFormData: boolean;
  getFormDataValues: (options?: FormDataOptions) => Promise<FormData>;
};

const useFormDataValidator = (
  formValues: TFormData,
  company: Company,
  moveConfig: MoveConfig | null,
): TFormDataValidator => {
  const hasProvider = useMemo((): boolean => {
    return !!formValues?.provider;
  }, [formValues]);

  const hasMovimentationType = useMemo((): boolean => {
    return !!formValues?.movimentationType;
  }, [formValues]);

  const hasMovimentationFile = useMemo((): boolean => {
    return !!formValues?.files;
  }, [formValues]);

  const hasLayout = useMemo((): boolean => {
    return Boolean(formValues?.layout) && formValues?.layout !== MOVE_LAYOUT_DEFAULT;
  }, [formValues]);

  const isValidFormData = useMemo((): boolean => {
    return ((hasProvider && hasMovimentationType) || hasLayout) && hasMovimentationFile;
  }, [hasProvider, hasMovimentationType, hasLayout, hasMovimentationFile]);

  const getFormDataValues = async (options?: FormDataOptions): Promise<FormData> => {
    const { provider, scope, movimentationType, files, attachment, layout } = formValues;
    const formData = new FormData();
    const data = decodedJwtToken();

    formData.append('userName', data.user.name);
    formData.append('userId', data.sub);
    formData.append('userEmail', data.user.email);
    formData.append('scope', scope);
    formData.append('companyId', company.id!);
    formData.append('files', files!);
    formData.append('layout', layout ?? MOVE_LAYOUT_DEFAULT);

    if (moveConfig?.moveType) {
      formData.append('movimentationType', moveConfig.moveType);
    }

    if (!layout || layout === MOVE_LAYOUT_DEFAULT) {
      formData.append('movimentationType', movimentationType!);
      formData.append('providerId', provider?.id!);
    }

    if (attachment) {
      formData.append('files', attachment);
    }

    if (options?.skipRequiredValidations) {
      options.skipRequiredValidations.forEach((skipRequiredValidation) => {
        formData.append('skipRequiredValidations', skipRequiredValidation);
      });
    }

    return formData;
  };

  return {
    hasProvider,
    hasMovimentationType,
    hasMovimentationFile,
    isValidFormData,
    getFormDataValues,
  };
};

export default useFormDataValidator;
