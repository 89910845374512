import TableLineCell from '@/components/dataGrid/components/tableLineCell/tableLineCell';
import { DataGridColumn } from '@/components/dataGrid/entities/types';
import { BaseSearchParams } from '@/shared/entities';
import { DataTableColumn } from 'vkit/lib/components/dataTable/types/dataTableColumn.types';
import { DataTableSort } from 'vkit/lib/components/dataTable/types/dataTableSort.types';

export const convertDataGridColumnsToDataTableColumns = <Data = any, Filter = BaseSearchParams>(
  dataGridColumns: DataGridColumn<Data, Filter>[],
  onSort: DataTableSort['onSort'],
): DataTableColumn[] => {
  return dataGridColumns.map(
    (
      {
        headerName,
        field,
        render,
        width,
        minWidth,
        maxWidth,
        align,
        sortable,
        sup,
        sub,
        description,
        formatData,
        type,
        textParams,
        chipParams,
        imageParams,
        translate,
      },
      index,
    ) => ({
      name: headerName,
      path: field ? String(field) : `column_path_${index}`,
      width,
      minWidth,
      maxWidth,
      sortable,
      onSort,

      format: (item: Data) => (
        <TableLineCell
          data={item}
          field={field}
          render={render}
          align={align}
          sup={sup}
          sub={sub}
          description={description}
          formatData={formatData}
          type={type}
          textParams={textParams}
          chipParams={chipParams}
          imageParams={imageParams}
          translate={translate}
        />
      ),
    }),
  );
};
