import { DataGridAction } from '@/components/dataGrid/entities/types';
import ICONS from '@/shareds/constants/icons';
import { DataTableAction } from 'vkit/lib/components/dataTable/types/dataTableAction.types';

interface ConvertDataGridActionsToDataTableActions<Action> {
  dataGridActions: DataGridAction<Action>[];
  checkConfirmation: (title: string, text: string) => Promise<boolean>;
  companyId?: string | null;
  navigate?: (route: string) => void;
}

export const convertDataGridActionsToDataTableActions = <Action>({
  dataGridActions,
  checkConfirmation,
  companyId,
  navigate,
}: ConvertDataGridActionsToDataTableActions<Action>): DataTableAction => {
  return {
    grouped: dataGridActions.length > 1,

    items: (item) =>
      dataGridActions.map((action) => {
        const actionToDetail = action.redirectToDetail && {
          icon: ICONS.DETAILS,
          label: 'Ver detalhes',
        };

        return {
          outlined: true,
          solo: true,
          invertColor: true,
          iconColor: action.disabled ? 'light' : action.color,
          ...actionToDetail,
          ...action.actionParams?.(item),
          ...action,
          onClick: async () => {
            const message = action.confirmText || 'Quer mesmo executar esta ação?';
            const canCallAction = !action.confirm || (await checkConfirmation('Atenção', message));
            if (!canCallAction) {
              return;
            }

            action.onClick?.(item);
            const route =
              action.redirectTo?.(item, companyId) || action.redirectToDetail?.(item, companyId);
            if (route) {
              navigate?.(route);
            }
          },
        };
      }),
  };
};
