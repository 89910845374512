import { FilterStateController } from '@/components/dataGrid/entities/types';
import { BasePagination } from '@/shared/entities';
import {
  LIST_INITIAL_PAGE,
  LIST_INITIAL_PAGE_SIZE,
  LIST_INITIAL_TOTAL,
  LIST_INITIAL_TOTAL_PAGES,
} from '@/shared/entities/constants/list';
import removeEmptyParams from '@/utils/removeEmptyParams';
import { tryOrCatchMessageError } from '@/utils/tryOrCatchMessageError';
import { omit } from 'lodash';
import { useCallback, useEffect, useRef, useState } from 'react';

export type ListResponse<T = any> = Partial<BasePagination> & {
  data: T[];
};

export interface UsePagedListOptions<Response extends ListResponse, Filter> {
  source: (filters: Filter, companyId: string) => Promise<Response> | Response;
  paramsToFilter?: FilterStateController;
  companyId: string;
  externalLoading?: boolean;
  omitParamsToFilter?: string[];
}

export const usePaginatedList = <Response extends ListResponse, Filter>({
  source,
  paramsToFilter,
  companyId = '',
  omitParamsToFilter = [],
  externalLoading,
}: UsePagedListOptions<Response, Filter>) => {
  const [data, setData] = useState<Response['data']>([]);
  const [page, setPage] = useState(LIST_INITIAL_PAGE);
  const [pageSize, setPageSize] = useState(LIST_INITIAL_PAGE_SIZE);
  const [total, setTotal] = useState(LIST_INITIAL_TOTAL);
  const [totalPages, setTotalPages] = useState(LIST_INITIAL_TOTAL_PAGES);
  const [loading, setLoading] = useState(true);

  const sourceRef = useRef(source);
  const canLoadDataRef = useRef(true);
  const omitParamsToFilterRef = useRef(omitParamsToFilter);

  const loadData = useCallback(
    async (companyIdToFilter: string, filters: Filter) => {
      if (!canLoadDataRef.current || externalLoading) {
        return;
      }

    await tryOrCatchMessageError(
      async () => {
        const response = await sourceRef.current(
          removeEmptyParams(omit(filters as object, omitParamsToFilterRef.current)),
          companyIdToFilter,
        );

          setData(response.data);
          response.total && setTotal(response.total);
          response.totalPages && setTotalPages(response.totalPages);
        },
        {
          tryBefore: () => {
            setLoading(true);
            setData([]);
            canLoadDataRef.current = false;
          },
          tryAfter: () => {
            setLoading(false);
            canLoadDataRef.current = true;
          },
          messageErrorDefault: 'Não foi possível carregar a lista, tente novamente mais tarde.',
        },
      );
    },
    [externalLoading],
  );

  useEffect(() => {
    if (!externalLoading) {
      sourceRef.current = source;
    }
  }, [externalLoading, source]);

  useEffect(() => {
    setPage(Number(paramsToFilter?.page) || LIST_INITIAL_PAGE);
  }, [paramsToFilter?.page]);

  useEffect(() => {
    setPageSize(Number(paramsToFilter?.pageSize) || LIST_INITIAL_PAGE_SIZE);
  }, [paramsToFilter?.pageSize]);

  useEffect(() => {
    loadData(companyId, {
      page: LIST_INITIAL_PAGE,
      pageSize: LIST_INITIAL_PAGE_SIZE,
      ...paramsToFilter,
    } as Filter);
  }, [companyId, loadData, page, pageSize, paramsToFilter]);

  return {
    data,
    page,
    pageSize,
    total,
    totalPages,
    loading,
    setPage,
    setPageSize,
  };
};
