import { Divider } from '@/components';
import BoxInfo from '@/components/boxInfo';
import Filters, { FieldsToFilter } from '@/components/dataGrid/components/filters/filters';
import Slots, { SlotsProps } from '@/components/dataGrid/components/slots/slots';
import { DataGridAction, DataGridColumn } from '@/components/dataGrid/entities/types';
import {
  UseParamsState,
  useParamsState,
} from '@/components/dataGrid/hooks/useParamsState/useParamsState';
import {
  ListResponse,
  UsePagedListOptions,
  usePaginatedList,
} from '@/components/dataGrid/hooks/useRequestData/usePaginatedList';
import { useSortParam } from '@/components/dataGrid/hooks/useSortParam/useSortParam';
import { convertDataGridActionsToDataTableActions } from '@/components/dataGrid/utils/convertDataGridActionsToDataTableActions';
import { convertDataGridColumnsToDataTableColumns } from '@/components/dataGrid/utils/convertDataGridColumnsToDataTableColumns';
import { useHistoryNavigator } from '@/navigation';
import { BaseSearchParams } from '@/shared/entities';
import { LIST_INITIAL_PAGE, LIST_INITIAL_PAGE_SIZE } from '@/shared/entities/constants/list';
import { useChangeState } from '@/shared/hooks/useChangeState';
import useCompany from '@/shared/hooks/useCompany/useCompany';
import useConfirm from '@/shareds/hooks/useConfirm';
import React from 'react';
import { DataTable } from 'vkit/lib/components';
import { Grid } from 'vkit/lib/context';

interface DataGridProps<
  Response extends ListResponse,
  Filter extends BaseSearchParams,
  Data = Response['data'][0],
> {
  filterStateController?: UseParamsState<Filter>['externalParamStateController'];
  source: UsePagedListOptions<Response, Filter>['source'];
  columns: DataGridColumn<Data, Filter>[];
  actionsByItem?: DataGridAction<Data>[];
  slots?: {
    header?: Partial<SlotsProps>;
    footer?: Partial<SlotsProps>;
  };
  elevation?: number;
  onClickItem?: (item: Data) => void;
  doNotReadQueryString?: boolean;
  filters?: FieldsToFilter<Filter>[];
  showPagination?: boolean;
  initialFilterValue?: (companyId: string | null) => Partial<Filter>;
  loading?: boolean;
}

const DataGrid = function <Response extends ListResponse, Filter extends BaseSearchParams>({
  slots,
  columns,
  actionsByItem,
  source,
  elevation,
  onClickItem,
  doNotReadQueryString,
  filterStateController,
  filters,
  showPagination = true,
  initialFilterValue,
  loading: externalLoading,
}: DataGridProps<Response, Filter>) {
  const { companyId } = useCompany();
  const defaultState = {
    page: LIST_INITIAL_PAGE,
    pageSize: LIST_INITIAL_PAGE_SIZE,
    ...(filterStateController?.[0] as Filter),
    ...initialFilterValue?.(companyId),
  };

  const [paramsState, setParamsState] = useParamsState<Filter>({
    externalParamStateController: filterStateController,
    doNotReadQueryString: filterStateController ? false : doNotReadQueryString,
    initialState: defaultState,
  });
  const [paramsFiltersBox, setParamsFiltersBox] = useParamsState<{ showMoreFilters: boolean }>({
    doNotReadQueryString,
    initialState: { showMoreFilters: false },
  });

  const changeParam = useChangeState(setParamsState, { ...defaultState, ...paramsFiltersBox });
  const { confirm, dialogConfirm } = useConfirm();
  const { onChangeSort } = useSortParam({ changeParam });
  const navigate = useHistoryNavigator();

  const { data, loading, total, totalPages, page, pageSize } = usePaginatedList<Response, Filter>({
    source,
    companyId: companyId || '',
    paramsToFilter: paramsState,
    omitParamsToFilter: ['showMoreFilters'],
    externalLoading,
  });

  return (
    <Grid gap={8} column stretch>
      {dialogConfirm}

      {filters && (
        <BoxInfo elevation={elevation}>
          <Filters
            values={paramsState}
            fields={filters}
            handleChange={(values) =>
              changeParam({
                ...values,
                showMoreFilters: paramsFiltersBox.showMoreFilters,
              })
            }
            setShowMoreFilters={(show) =>
              setParamsFiltersBox({ ...paramsState, showMoreFilters: show })
            }
            showMoreFilters={paramsFiltersBox.showMoreFilters}
          />
        </BoxInfo>
      )}

      <BoxInfo padding={0} elevation={elevation}>
        {slots?.header && (
          <>
            <Slots {...slots.header} />
            <Divider space={0} />
          </>
        )}

        <DataTable
          columns={convertDataGridColumnsToDataTableColumns(columns, onChangeSort)}
          action={
            actionsByItem &&
            convertDataGridActionsToDataTableActions({
              dataGridActions: actionsByItem,
              checkConfirmation: confirm,
              companyId,
              navigate: (route) => navigate.push(route),
            })
          }
          data={data}
          isLoading={loading || Boolean(externalLoading)}
          total={total}
          totalPages={totalPages}
          page={page}
          pageSize={pageSize}
          showPagination={showPagination && Boolean(total)}
          elevation={0}
          outlined={false}
          onChangePage={(page) => changeParam({ page } as Filter)}
          onChangePageSize={(pageSize) => changeParam({ pageSize, page: 1 } as Filter)}
          onRowClick={() => onClickItem}
        />

        {slots?.footer && (
          <>
            <Divider space={0} />
            <Slots {...slots.footer} />
          </>
        )}
      </BoxInfo>
    </Grid>
  );
};

export default DataGrid;
