import React, { useState } from 'react';

import { ShowHide } from '@/components';
import {
  MovesValidationError,
  movesValidationErrorInitialState,
} from '@/services/files/endpoints/moves';
import UploadForm from '@/modules/cadastralPosition/views/uploadForm/uploadForm';
import { CADASTRAL_POSITION_UPLOAD_STEPS } from '@/modules/cadastralPosition/entities/cadastralPosition.enum';
import Success from '@/modules/cadastralPosition/views/success/success';
import Errors from '@/modules/cadastralPosition/views/errors/errors';

const Upload: React.FC = () => {
  const [currentStep, setCurrentStep] = useState<CADASTRAL_POSITION_UPLOAD_STEPS>(
    CADASTRAL_POSITION_UPLOAD_STEPS.FORM,
  );
  const [errors, setErrors] = useState<MovesValidationError>(movesValidationErrorInitialState);

  return (
    <>
      <ShowHide
        transition='slideToDown'
        visible={currentStep === CADASTRAL_POSITION_UPLOAD_STEPS.FORM}>
        <UploadForm
          setCurrentStep={setCurrentStep}
          setErrors={setErrors}
          validationError={errors}
        />
      </ShowHide>

      <ShowHide
        transition='slideToDown'
        visible={currentStep === CADASTRAL_POSITION_UPLOAD_STEPS.ERROR}>
        <Errors errors={errors} setCurrentStep={setCurrentStep} />
      </ShowHide>

      <ShowHide
        transition='slideToDown'
        visible={currentStep === CADASTRAL_POSITION_UPLOAD_STEPS.SUCCESS}>
        <Success />
      </ShowHide>
    </>
  );
};

export default Upload;
