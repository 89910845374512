import { getErrorDescription } from '@/pages/moves/upload/utils/getErrorDescription';
import { getFieldNames, getLines } from '@/pages/moves/upload/utils/utils';
import { MovesValidationErrorDetail } from '@/services/files/endpoints/moves';
import translateRulesValidator from '@/utils/translateRulesValidator';
import React from 'react';
import { DataTable } from 'vkit/lib/components';

interface DataTableErrorsProps {
  details: MovesValidationErrorDetail[];
}

const DataTableErrors: React.FC<DataTableErrorsProps> = ({ details }) => {
  return (
    <DataTable
      isLoading={false}
      showPagination={false}
      elevation={4}
      data={details.filter((error) => !error.skippable)}
      columns={[
        {
          name: 'Linha',
          path: 'fields',
          format: (error) => getLines(error),
        },
        {
          name: 'Coluna',
          path: 'fields',
          format: (error) => getFieldNames(error),
        },
        {
          name: 'Valor encontrado',
          path: 'value',
        },
        {
          name: 'Valor esperado/problema',
          path: 'rule',
          format: (movesValidationErrorDetail: MovesValidationErrorDetail) =>
            translateRulesValidator(
              movesValidationErrorDetail.rule,
              movesValidationErrorDetail.details?.expected,
            ) || getErrorDescription(movesValidationErrorDetail),
        },
      ]}
    />
  );
};

export default DataTableErrors;
