import { useCallback } from 'react';
import { SORT_DIRECTION } from 'vkit/lib/components/dataTable/constants/sort';

interface UseSortParam<Filter> {
  changeParam: (value: Filter) => void;
  value?: string | string[];
}

export function useSortParam<Filter>({ changeParam }: UseSortParam<Filter>) {
  const onChangeSort = useCallback(
    (columnPath: string, sortDirection: SORT_DIRECTION) => {
      const signDirection = sortDirection === SORT_DIRECTION.DESC ? '-' : '';
      const sort =
        sortDirection === SORT_DIRECTION.NONE ? undefined : `${signDirection}${columnPath}`;

      changeParam({
        sort,
      } as Filter);
    },
    [changeParam],
  );

  return {
    onChangeSort,
  };
}
