import SelectTranslate, { SelectTranslateProps } from '@/components/selectTranslate/selectTranslate';
import TextFieldDebounce, { TextFieldDebounceProps } from '@/components/textFieldDebounce/textFieldDebounce';
import SelectCompanyChips, { SelectCompanyChipsProps } from '@/modules/company/views/selectCompanyChips/selectCompanyChips';
import ICONS from '@/shareds/constants/icons';
import React from 'react';
import { Select } from 'vkit/lib/components';
import SelectType from 'vkit/lib/components/webform/select/types/select.type';

export const fieldByType = {
  text: (params: TextFieldDebounceProps) => (
    <TextFieldDebounce
      small
      icon={ICONS.SEARCH}
      iconDir='right'
      clearable
      {...params}
    />
  ),
  date: (params: TextFieldDebounceProps) => (
    <TextFieldDebounce
      type='date'
      iconDir='right'
      small
      clearable
      {...params}
    />
  ),
  number: (params: TextFieldDebounceProps) => (
    <TextFieldDebounce
      type='number'
      iconDir='right'
      icon={ICONS.SEARCH}
      small
      clearable
      {...params}
    />
  ),
  cpf: (params: TextFieldDebounceProps) => (
    <TextFieldDebounce
      mask='999.999.999-99'
      iconDir='right'
      icon={ICONS.SEARCH}
      small
      clearable
      {...params}
    />
  ),
  cep: (params: TextFieldDebounceProps) => (
    <TextFieldDebounce
      mask='99999-99'
      iconDir='right'
      icon={ICONS.SEARCH}
      small
      clearable
      {...params}
    />
  ),
  cnpj: (params: TextFieldDebounceProps) => (
    <TextFieldDebounce
      mask='99.999.999/9999-99'
      iconDir='right'
      icon={ICONS.SEARCH}
      small
      clearable
      {...params}
    />
  ),
  select: (params: Partial<SelectType>) => <Select { ...params } small clearable value={params.value || ''} />,
  selectTranslate: (params: Partial<SelectTranslateProps>) => (
    <SelectTranslate
      { ...params }
      small
      clearable
      value={params.value || ''}
      translate={params.translate || {}}
    />
  ),
  selectCompanyChips: (params: SelectCompanyChipsProps) => <SelectCompanyChips { ...params } size='small' />,
}
