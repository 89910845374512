import DialogConfirmSkipErrors from '@/modules/move/components/dialogConfirmSkipErrors/dialogConfirmSkipErrors';
import { MovesValidationErrorDetail } from '@/services/files/endpoints/moves';
import React, { Dispatch, SetStateAction, useMemo } from 'react';

const cpfFieldByLayoutName: Record<string, string> = {
  'creation-stone': 'human.cpf',
  'creation-insper': 'CPF Dependente',
  'creation-health-cadmus': 'CPF_Dependente',
  'creation-dental-cadmus': 'CPF_Dependente',
  default: 'CPF',
};

interface DialogConfirmBeneficiaryWithoutCPFProps {
  layoutName?: string | null;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  errorDetails: MovesValidationErrorDetail[];
  onConfirm: (cpfField: string) => void;
}

const DialogConfirmBeneficiaryWithoutCPF: React.FC<DialogConfirmBeneficiaryWithoutCPFProps> = ({
  layoutName = 'default',
  open,
  setOpen,
  errorDetails,
  onConfirm,
}) => {
  const CPFRequiredErrors = useMemo(() => {
    return errorDetails.filter(
      (errorDetail) =>
        ['required', 'requiredInGroup'].includes(errorDetail.rule) &&
        errorDetail.fields.some((field) => field.match(/cpf/i)),
    );
  }, [errorDetails]);

  const messageOnMetadataTable = useMemo(() => {
    return CPFRequiredErrors.length > 1
      ? `Sua movimentação atual possui <b>${CPFRequiredErrors.length} dependentes sem CPF.</b>`
      : 'Sua movimentação atual possui <b>1 dependente sem CPF.</b>';
  }, [CPFRequiredErrors.length]);

  return (
    <DialogConfirmSkipErrors
      open={open}
      setOpen={setOpen}
      errorDetails={CPFRequiredErrors}
      messageConfirm={
        'Ao continuar, você concorda que um ou mais dependentes não possuem CPF e, ' +
        'por isso, <b>não poderão utilizar os benefícios do Hospital Digital Vitta</b>. ' +
        'Deseja continuar?'
      }
      onConfirm={() =>
        onConfirm(layoutName ? cpfFieldByLayoutName[layoutName] : cpfFieldByLayoutName.default)
      }
      showMetadataTable={true}
      messageOnMetadataTable={messageOnMetadataTable}
      metadataHeaderNameOnErrorDetails='Nome'
      metadataKeyOnErrorDetails='beneficiaryName'
      titleOnMetadataTable='Dependentes sem CPF'
    />
  );
};

export default DialogConfirmBeneficiaryWithoutCPF;
