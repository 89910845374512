import { TextFieldValidate } from '@/components';
import DataGrid from '@/components/dataGrid/dataGrid';
import { EventLogData, EventLogParams } from '@/modules/audit/entities';
import { AuditService } from '@/modules/audit/services';
import { SelectCompany } from '@/modules/company';
import SelectUser from '@/modules/user/components/selectUser/selectUser';
import ICONS from '@/shareds/constants/icons';
import React, { useState } from 'react';
import DetailsDialog from '@/modules/audit/components/detailsDialog/detailsDialog';
import SelectOptions from '@/modules/audit/components/selectOptions/selectOptions';
import { TextField } from 'vkit/lib/components';

const Home: React.FC = () => {
  const [isDetailsOpen, setDetailsOpen] = useState<boolean>(false);
  const [currEventLogData, setCurrEventLogData] = useState<EventLogData[]>([]);

  return (
    <>
      <DetailsDialog
        open={isDetailsOpen}
        data={currEventLogData}
        fnCallback={() => {
          setDetailsOpen(false);
          setCurrEventLogData([]);
        }}
      />
      <DataGrid
        source={(filters: EventLogParams) =>
          new AuditService().getEventLogs({
            ...filters,
            include: ['eventLogData', 'eventLogAction', 'eventLogEntity'],
            sort: '-eventTime',
          })
        }
        filters={[
          {
            render: (filters, changeFilterData) => (
              <SelectUser
                small
                label='Usuário'
                selectedUserId={filters?.userId}
                onChangeSelectedUser={(value) => changeFilterData({ userId: value })}
              />
            ),
          },
          {
            render: (filters, changeFilterData) => (
              <SelectCompany
                small
                label='Empresa'
                clearable
                selectedCompanyId={filters?.companyId}
                onChangeSelectedCompany={(value) => changeFilterData({ companyId: value })}
              />
            ),
          },
          {
            render: (filters, changeFilterData) => (
              <SelectOptions
                searchable
                endpoint='/event-log-actions'
                small
                label='Ação'
                placeholder='Filtre por ação'
                value={filters?.eventLogActionId}
                onChangeValue={(value) => changeFilterData({ eventLogActionId: value })}
              />
            ),
          },
          {
            width: 25,
            render: (filters, changeFilterData) => (
              <SelectOptions
                endpoint='/event-log-entities'
                small
                label='Entidade'
                placeholder='Filtre por entidade'
                value={filters?.eventLogEntityId}
                onChangeValue={(value) => changeFilterData({ eventLogEntityId: value })}
              />
            ),
          },
          {
            width: 25,
            render: (filters, changeFilterData) => (
              <TextField
                small
                clearable
                label='Id da entidade'
                value={filters?.entityRecordId}
                onChange={(value) => changeFilterData({ entityRecordId: value })}
              />
            ),
          },
          {
            width: 25,
            render: (filters, changeFilterData) => (
              <TextFieldValidate
                small
                name='eventTime.GTE'
                label='Data inicial'
                useValues={filters}
                onChange={(key: string, value: string) => changeFilterData({ [key]: value })}
                type='date'
              />
            ),
          },
          {
            width: 25,
            render: (filters, changeFilterData) => (
              <TextFieldValidate
                small
                name='eventTime.LTE'
                label='Data final'
                useValues={filters}
                onChange={(key: string, value: string) => changeFilterData({ [key]: value })}
                type='date'
              />
            ),
          },
        ]}
        columns={[
          {
            headerName: 'Nome',
            field: 'userName',
            sub: {
              field: 'userEmail',
            },
          },
          {
            headerName: 'Ação',
            field: 'eventLogAction.name',
          },
          {
            headerName: 'IP/Navegador',
            field: 'userIp',
            sub: {
              field: 'userAgent',
            },
          },
          {
            headerName: 'Entidade',
            field: 'eventLogEntity.name',
            sub: {
              field: 'entityRecordId',
            },
          },
          {
            headerName: 'Data',
            field: 'eventTime',
            formatData: 'dateTime',
          },
        ]}
        actionsByItem={[
          {
            label: 'Ver detalhes',
            icon: ICONS.DETAILS,
            actionParams: ({ eventLogData }) => ({
              iconColor: eventLogData.length ? 'colorDefault' : 'light',
              disabled: !eventLogData.length,
              description: !eventLogData.length
                ? 'Ação desabilitada por não possuir registro de detalhes'
                : undefined,
            }),
            onClick: ({ eventLogData }) => {
              setCurrEventLogData(eventLogData);
              setDetailsOpen(true);
            },
          },
        ]}
        doNotReadQueryString
      />
    </>
  );
};

export default Home;
