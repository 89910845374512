import ChipTranslate from '@/components/chipTranslate';
import { DataGridCell } from '@/components/dataGrid/entities/types';
import { Translate } from '@/shared/entities/types/translate';
import formatText, { FormatData } from '@/utils/formatText';
import { get } from 'lodash';
import React, { useMemo } from 'react';
import { Chip } from 'vkit/lib/components';
import { Grid } from 'vkit/lib/context';

type TableLineCellTextProps<Data, Filter> = DataGridCell<Data, Filter>['chipParams'] & {
  data: Data;
  field: string;
  align?: 'center' | 'left' | 'right';
  type?: FormatData;
  translate?: Translate;
}

const TableLineCellChip = function<Data, Filter>(
  {
    data,
    field,
    align,
    type = 'text',
    translate,
    ...chipParams
  }:TableLineCellTextProps<Data, Filter>
) {
  const value = useMemo(() => get(data, field), [data, field]);

  return (
    <Grid stretch alignContent={align}>
      {
        translate ? (
          <ChipTranslate term={value} translate={translate} />
        ) : (
          <Chip rounded size='small' label={formatText(value, type)} {...chipParams} />
        )
      }
    </Grid>
  );
};

export default TableLineCellChip;
