import { Space } from '@/components';
import DataTableErrors from '@/shared/components/dataTableErrors/dataTableErrors';
import React from 'react';
import { MovesValidationErrorDetail } from '@/services/files/endpoints/moves';
import { Button, Text } from 'vkit/lib/components';
import { Grid } from 'vkit/lib/context';

export interface ErrosProps {
  details: MovesValidationErrorDetail[];
  setUploadFormStatus: Function;
}

const Errors: React.FC<ErrosProps> = ({ details, setUploadFormStatus }) => {
  return (
    <>
      <Text
        applyBaseColor
        color='danger'
        fontWeight='bold'
        icon='alert-triangle-outline'
        iconColor='danger'
        margin='0 0 16px'
        padding='8px 16px'
        rounded
        size='larger'
        value={`Ops! Não conseguimos processar a sua solicitação de movimentação. 
        Detectamos algumas informações incorretas ou ausentes nos campos destacados na tabela abaixo. 
        Por favor, verifique os seguintes campos abaixo e tente novamente.`}
      />

      <DataTableErrors details={details} />

      <Space horizontal />

      <Grid alignContent='right'>
        <Button label='Enviar novo arquivo' onClick={() => setUploadFormStatus('form')} />
      </Grid>
    </>
  );
};

export default Errors;
