import { COMPANY_CONFIG } from '@/modules/company/entities/company.enum';
import { MOVE_LAYOUT_DEFAULT } from '@/modules/move/entities';
import useCompany from '@/shared/hooks/useCompany/useCompany';
import { useEffect, useMemo, useState } from 'react';
import { TFormData } from '../types/FormData.type';

export type ComponentsType = { [key: string]: any };

type UseComponentsVisibilityProps = {
  formData: TFormData;
  hasError: boolean;
  hasSuccess: boolean;
  isSkipplableError: boolean;
};

const useComponentsVisibility = ({
  formData,
  hasError,
  hasSuccess,
  isSkipplableError,
}: UseComponentsVisibilityProps) => {
  const { getAllCompanyConfigsByKey } = useCompany();
  const hasMoveLayoutConfig = getAllCompanyConfigsByKey(COMPANY_CONFIG.MOVES_LAYOUT).length > 0;
  const [showDialogConfirmBeneficiaryWithoutCPF, setShowDialogConfirmBeneficiaryWithoutCPF] =
    useState(false);

  const showMovimentationType = useMemo(() => {
    return !!formData.provider;
  }, [formData]);

  const showUploadField = useMemo(() => {
    if (
      hasMoveLayoutConfig &&
      Boolean(formData.layout) &&
      formData.layout !== MOVE_LAYOUT_DEFAULT
    ) {
      return true;
    }

    return !!formData.movimentationType;
  }, [formData.layout, formData.movimentationType, hasMoveLayoutConfig]);

  const showFormComponents = useMemo(() => {
    return !hasError && !hasSuccess;
  }, [hasError, hasSuccess]);

  const showSubmitError = useMemo(() => {
    return hasError && !hasSuccess && !isSkipplableError;
  }, [hasError, hasSuccess, isSkipplableError]);

  const showSubmitSuccess = useMemo(() => {
    return !hasError && hasSuccess;
  }, [hasError, hasSuccess]);

  const showSelectProvider = useMemo(() => {
    return !hasMoveLayoutConfig || formData.layout === MOVE_LAYOUT_DEFAULT;
  }, [formData.layout, hasMoveLayoutConfig]);

  const showSelectLayout = useMemo(() => {
    return hasMoveLayoutConfig;
  }, [hasMoveLayoutConfig]);

  const showDownloadField = useMemo(() => {
    if (
      hasMoveLayoutConfig &&
      Boolean(formData.layout) &&
      formData.layout !== MOVE_LAYOUT_DEFAULT
    ) {
      return false;
    }

    return !!formData.movimentationType;
  }, [formData.layout, formData.movimentationType, hasMoveLayoutConfig]);

  useEffect(() => {
    setShowDialogConfirmBeneficiaryWithoutCPF(isSkipplableError);
  }, [isSkipplableError]);

  return {
    showMovimentationType,
    showUploadField,
    showFormComponents,
    showSubmitError,
    showSubmitSuccess,
    showSelectProvider,
    showSelectLayout,
    showDownloadField,
    showDialogConfirmBeneficiaryWithoutCPF,
    setShowDialogConfirmBeneficiaryWithoutCPF,
  };
};

export default useComponentsVisibility;
