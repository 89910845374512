import restfulBase from '@/services/files/core/base';

const apiService = restfulBase('moves');

export enum VALIDATION_ERROR_TYPE {
  FIELD_NAME = 'fieldName',
  FIELD_VALUE = 'fieldValue',
}

export interface MovesValidationErrorDetail {
  type: VALIDATION_ERROR_TYPE;
  fields: string[];
  page?: string;
  message: string;
  rule: string;
  value: string;
  details?: {
    expected: string[] | string | number;
    actual: string;
  };
  metadata: Record<string, unknown>;
  skippable?: boolean;
}

export interface MovesValidationError {
  details: MovesValidationErrorDetail[];
  message: string;
  skippable?: boolean;
}

export const movesValidationErrorInitialState: MovesValidationError = {
  details: [],
  message: '',
  skippable: false,
};

export default apiService;
