import DataGrid from '@/components/dataGrid/dataGrid';
import { Contract, ContractFilter, CONTRACTS_TABS, ContractService } from '@/modules/contract';
import ContractStatus from '@/modules/contract/components/contractStatus/contractStatus';
import TabContractStatuses from '@/modules/contract/components/tabContractStatuses/tabContractStatuses';
import { LogoProvider } from '@/modules/provider';
import { BaseListResponse } from '@/shared/entities';
import { useQueryStringState } from '@/shared/hooks/useQueryStringState';
import { stringArrayToText } from '@/utils/arrayToText/stringArrayToText';
import { get, omit, uniq } from 'lodash';
import React from 'react';

const List: React.FC = () => {
  const [filters, setFilters] = useQueryStringState<ContractFilter>({
    status: CONTRACTS_TABS[0].status,
    pageSize: 50,
  });

  const getContracts = function (filters: ContractFilter, companyId: string) {
    const paramsToFilter = filters.status === 'all' ? omit(filters, 'status') : filters;
    return new ContractService().getContracts<BaseListResponse<Contract>>(
      companyId,
      paramsToFilter,
    );
  };

  return (
    <>
      <TabContractStatuses setFilters={setFilters} initialActiveTabStatus={filters.status} />

      <DataGrid
        filterStateController={[filters, setFilters]}
        source={getContracts}
        columns={[
          {
            render: (contract) => {
              const providerCnpj = get(contract, 'provider.cnpj', 'not-found');
              return <LogoProvider providerCnpj={String(providerCnpj)} size='small' />;
            },
          },
          {
            headerName: 'Fornecedor',
            field: 'provider.name',
          },
          {
            headerName: 'Benefício',
            render: (contract) => {
              const benefits =
                contract.negotiations?.map((negotiation) =>
                  get(negotiation, 'product.benefit.name', ''),
                ) || [];

              return stringArrayToText(uniq(benefits).sort());
            },
          },
          {
            headerName: 'Código',
            field: 'code',
          },
          {
            headerName: 'Início de vigência',
            field: 'validFrom',
            formatData: 'date',
          },
          {
            headerName: 'Fim de vigência',
            field: 'validUntil',
            formatData: 'date',
          },
          {
            headerName: 'Status',
            render: (contract) => <ContractStatus contract={contract} />,
          },
        ]}
        actionsByItem={[
          {
            redirectToDetail: ({ id }, companyId) => `/companies/${companyId}/contracts/${id}`,
          },
        ]}
        showPagination={false}
      />
    </>
  );
};

export default List;
