import { ModalLogin } from '@/modules/authentication/components/modalLogin';
import { isEmpty, omit } from 'lodash';
import React, { useEffect, useMemo } from 'react';

import { checkMobile, classesBuilder } from '@/utils';

import { Header } from '../../components';

import useMainPageDetails from '@/core/hooks/useMainPageDetails';
import { Outlet } from 'react-router-dom';
import { Grid } from 'vkit/lib/context';
import style from '../../styles/main.module.scss';
import useSetPageTabTitle from '@/core/hooks/useSetPageTabTitle';

interface MainProps {
  invertPageHeaderAndChildren?: boolean;
}

const Main: React.FC<MainProps> = ({ children, invertPageHeaderAndChildren }) => {
  const { data } = useMainPageDetails();
  const { setPageTabTitle } = useSetPageTabTitle();
  const headerData = useMemo(() => omit(data, ['title', 'groupedBy']), [data]);

  useEffect(() => {
    setPageTabTitle(data?.title);
  }, [data?.title, setPageTabTitle]);

  return (
    <div
      className={classesBuilder(style, {
        main: true,
        mobile: checkMobile(),
      })}>
      <ModalLogin />
      <div className={style.content}>
        <Grid column gap={24}>
          {(!isEmpty(headerData) || children) && (
            <Grid
              column={!invertPageHeaderAndChildren}
              columnReverse={invertPageHeaderAndChildren}
              gap={24}
              stretch
              growing>
              <Header
                title={headerData.name}
                icon={headerData.icon}
                iconSrc={headerData.customImageUrl}
                hasGoBackAction={headerData.hasGoBackAction}
              />

              {children}
            </Grid>
          )}

          <Outlet />
        </Grid>
      </div>
    </div>
  );
};

export default Main;
