import { Tooltip } from '@/components';
import { findRoutePathByName } from '@/core/utils';
import { ROUTE_USER } from '@/modules/user/routes';
import { useHistoryNavigator, useHistoryParams } from '@/navigation';
import ICONS from '@/shareds/constants/icons';
import React, { useMemo } from 'react';
import { generatePath } from 'react-router-dom';
import { Button } from 'vkit/lib/components';

interface ButtonGoToCreateUserProps {
  disabledCreateAction?: boolean;
  routeIdToDetails: ROUTE_USER;
}

const ButtonGoToCreateUser: React.FC<ButtonGoToCreateUserProps> = ({
  disabledCreateAction,
  routeIdToDetails,
}) => {
  const { companyId } = useHistoryParams();
  const navigate = useHistoryNavigator();

  const tooltipMessage = disabledCreateAction
    ? 'Você não tem permissão para criar um novo usuário'
    : undefined;

  const creationRoute = useMemo(() => {
    if (disabledCreateAction) {
      return;
    }

    const routeFound = findRoutePathByName(routeIdToDetails);

    if (routeFound) {
      if (companyId) {
        return generatePath(routeFound, { companyId });
      }

      return generatePath(routeFound);
    }
  }, [companyId, disabledCreateAction, routeIdToDetails]);

  return (
    <Tooltip title={tooltipMessage}>
      <Button
        label='Novo usuário'
        icon={ICONS.USER}
        disabled={disabledCreateAction}
        onClick={() => navigate.push(creationRoute)}
      />
    </Tooltip>
  );
};

export default ButtonGoToCreateUser;
