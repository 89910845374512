import React from 'react';

import { useHistoryNavigator } from '@/navigation';
import { Button } from 'vkit/lib/components';

const ButtonGoBack: React.FC = () => {
  const navigator = useHistoryNavigator();

  return (
    <Button
      icon='arrow-ios-back-outline'
      iconColor='colorDefault'
      label='Voltar'
      invertColor
      onClick={() => navigator.goBack()}
    />
  );
};

export default ButtonGoBack;
