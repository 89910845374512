import BoxInfo from '@/components/boxInfo';
import { getLines } from '@/pages/moves/upload/utils/utils';
import { MovesValidationErrorDetail } from '@/services/files/endpoints/moves';
import React, { useMemo } from 'react';
import { DataTable, Text } from 'vkit/lib/components';
import { DataTableColumn } from 'vkit/lib/components/dataTable/types/dataTableColumn.types';
import { Grid } from 'vkit/lib/context';

export interface MetadataTableProps {
  title?: string;
  message?: string;
  headerNameTable: string;
  keyOnErrorMetadata: string;
  errorDetails: MovesValidationErrorDetail[];
}

const MetadataTable: React.FC<MetadataTableProps> = ({
  title,
  message,
  headerNameTable,
  keyOnErrorMetadata,
  errorDetails,
}) => {
  const columns = useMemo((): DataTableColumn[] => {
    return [
      {
        name: 'Linha',
        path: 'field',
        width: '30px',
        format: (item) => getLines(item),
      },
      {
        name: headerNameTable,
        path: `metadata.${keyOnErrorMetadata}`,
      },
    ];
  }, [headerNameTable, keyOnErrorMetadata]);

  return (
    <Grid column>
      {title && (
        <Grid margin='0 0 16px' alignContent='center' stretch>
          <Text value={title} title size='medium' color='colorDefault' />
        </Grid>
      )}

      {message && (
        <Grid margin='0 0 32px' alignContent='center' stretch>
          <Text value={message} size='medium' />
        </Grid>
      )}

      <BoxInfo padding={0} maxHeight={200} margin={0}>
        <DataTable
          columns={columns}
          data={errorDetails}
          isLoading={false}
          showPagination={false}
          elevation={0}
          outlined={false}
        />
      </BoxInfo>
    </Grid>
  );
};

export default MetadataTable;
