import { ShowHide } from '@/components';
import FilterField, { FieldProps } from '@/components/dataGrid/components/filterField/filterField';
import GridGroup from '@/components/gridGroup';
import React, { ReactNode, useMemo } from 'react';
import { Button } from 'vkit/lib/components';
import { Grid } from 'vkit/lib/context';

export type FieldsToFilter<Filter> = FieldProps & {
  key?: keyof Filter;
  extra?: boolean;
  render?: (filterData: Record<string, any>, changeFilterData: (data: Filter) => void) => ReactNode;
};

export interface FiltersProps<Filter> {
  fields: FieldsToFilter<Filter>[];
  handleChange: (params: Filter) => void;
  values?: Filter;
  showMoreFilters?: boolean;
  setShowMoreFilters: (showMoreFilters: boolean) => void;
}

const Filters = function <Filter>({
  fields,
  values,
  handleChange,
  showMoreFilters,
  setShowMoreFilters,
}: FiltersProps<Filter>) {
  const buttonIconColor = useMemo(() => (showMoreFilters ? 'light' : 'clear'), [showMoreFilters]);
  const buttonLabel = useMemo(
    () => (showMoreFilters ? 'Menos filtros' : 'Mais filtros'),
    [showMoreFilters],
  );

  const mainFields = useMemo(() => {
    return fields.filter((field) => !field.extra);
  }, [fields]);

  const extraFields = useMemo(() => {
    return fields.filter((field) => field.extra);
  }, [fields]);

  const fieldChangeValue = (filterData: Filter) => {
    handleChange({ ...filterData, page: 1 } as Filter);
  };

  const getFilterField = ({ key, ...field }: FieldsToFilter<Filter>) => ({
    default: field.width,
    middle: field.width,
    mobile: field.width,
    component: (
      <FilterField
        {...field}
        render={values && field.render?.(values, fieldChangeValue)}
        onChange={(value: unknown) => key && fieldChangeValue({ [key]: value } as Filter)}
        value={key && values?.[key]}
      />
    ),
  });

  return (
    <>
      <Grid gap={16} alignItems='bottom' alignContent='justify'>
        <GridGroup gap={16} body={mainFields.map(getFilterField)} />

        {extraFields.length ? (
          <Button
            iconColor={buttonIconColor}
            onClick={() => setShowMoreFilters(!showMoreFilters)}
            label={buttonLabel}
            icon='options-2-outline'
            color='light'
            invertColor={showMoreFilters}
          />
        ) : null}
      </Grid>

      <ShowHide visible={showMoreFilters} transition='slideToDown'>
        <Grid gap={16} alignItems='bottom' alignContent='justify' margin='16px 0 0'>
          <GridGroup gap={16} body={extraFields.map(getFilterField)} />
        </Grid>
      </ShowHide>
    </>
  );
};

export default Filters;
