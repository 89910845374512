import { useQueryStringState } from '@/shared/hooks/useQueryStringState';
import { Dispatch, SetStateAction, useCallback, useState } from 'react';

export interface UseParamsState<Params> {
  externalParamStateController?: [
    paramsState: Params,
    setParamsState: Dispatch<SetStateAction<Params>>,
  ];
  doNotReadQueryString?: boolean;
  initialState: Params;
}

export const useParamsState = <Params>({
  doNotReadQueryString,
  initialState,
  externalParamStateController,
}: UseParamsState<Params>) => {
  // eslint-disable-next-line
  const hookToState = useCallback(doNotReadQueryString ? useState : useQueryStringState, [
    doNotReadQueryString,
  ]);

  return externalParamStateController || hookToState<Params>(initialState);
};
