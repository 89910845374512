import { ChangeValue } from '@/components/formValidate/types/formValidate.type';

export interface ChangeOne extends ChangeValue {
  name: string;
  value: string;
}

export const changeOne = ({
  setToucheds,
  useToucheds,
  objectName,
  resource,
  onChangeForm,
  name,
  value,
}: ChangeOne) => {
  setToucheds({
    ...useToucheds,
    [name]: true,
  });

  let params: {};

  if (objectName) {
    const param = resource?.[objectName];

    params = {
      ...resource,
      [objectName]: {
        ...param,
        [name]: value,
      },
    };
  } else {
    params = {
      ...resource,
      [name]: value,
    };
  }

  onChangeForm?.(params);
};
