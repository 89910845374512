import { ContractFilter, CONTRACTS_TABS, useList } from '@/modules/contract';
import React, { Dispatch, SetStateAction, useEffect, useMemo } from 'react';
import { Tabs } from 'vkit/lib/components';

interface TabContractStatusesProps {
  setFilters: Dispatch<SetStateAction<ContractFilter>>;
  initialActiveTabStatus: string;
}

const TabContractStatuses: React.FC<TabContractStatusesProps> = ({
  setFilters,
  initialActiveTabStatus,
}) => {
  const initialActiveTabIndex = useMemo(() => {
    return CONTRACTS_TABS.findIndex((tab) => tab.status === initialActiveTabStatus);
  }, [initialActiveTabStatus]);

  const { activeTab, handleChangeTabs, renderTabs } = useList({
    initialActiveTab: initialActiveTabIndex,
    contractsTabs: CONTRACTS_TABS,
  });

  useEffect(() => {
    setFilters({
      status: CONTRACTS_TABS[activeTab].status,
      pageSize: 50,
    });
  }, [activeTab, setFilters]);

  return (
    <div data-testid='wrapper-tabs'>
      <Tabs iconDir='left' elements={[]} actions={renderTabs} onChange={handleChangeTabs} />
    </div>
  );
};

export default TabContractStatuses;
