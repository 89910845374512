import { BoxInfo, ShowHide, ToastStatus } from '@/components';
import DialogConfirmBeneficiaryWithoutCPF from '@/modules/move/components/dialogConfirmBeneficiaryWithoutCPF/dialogConfirmBeneficiaryWithoutCPF';
import ICONS from '@/shareds/constants/icons';
import React from 'react';
import { Loader } from 'vkit/lib/components';

import { Grid, Row } from 'vkit/lib/context';
import Success from '../success/success';
import {
  Action,
  Alert,
  Errors,
  SelectProvider,
  SelectType,
  TemplateDownload,
  UploadFile,
} from './components';
import SelectLayout from './components/SelectLayout';
import { useComponentsVisibility, useFormData } from './hooks';

const Upload: React.FC = () => {
  const { toast, useToast: triggerToast } = ToastStatus();

  const {
    formValues,
    setData,
    isDisabledSubmit,
    handleSubmit,
    isLoading,
    useError,
    hasSubmitError,
    resetSubmitError,
    hasSubmitSuccess,
    setCompanyMoveConfig,
    isSkipplableError,
  } = useFormData(triggerToast);

  const {
    showMovimentationType,
    showUploadField,
    showDownloadField,
    showFormComponents,
    showSubmitError,
    showSubmitSuccess,
    showSelectProvider,
    showSelectLayout,
    showDialogConfirmBeneficiaryWithoutCPF,
    setShowDialogConfirmBeneficiaryWithoutCPF,
  } = useComponentsVisibility({
    formData: formValues,
    hasError: hasSubmitError,
    hasSuccess: hasSubmitSuccess,
    isSkipplableError,
  });

  return (
    <>
      {isLoading && <Loader full />}

      <DialogConfirmBeneficiaryWithoutCPF
        layoutName={formValues.layout}
        open={showDialogConfirmBeneficiaryWithoutCPF}
        setOpen={setShowDialogConfirmBeneficiaryWithoutCPF}
        errorDetails={useError.details}
        onConfirm={(cpfField) => {
          handleSubmit({
            skipRequiredValidations: [cpfField],
          });
          setShowDialogConfirmBeneficiaryWithoutCPF(false);
        }}
      />

      <ShowHide displayNone transition='fade' visible={showFormComponents}>
        <BoxInfo title='Upload de movimentações' icon={ICONS.UPLOAD}>
          <form onSubmit={() => handleSubmit()}>
            <Grid column gap={32}>
              <ShowHide transition='fade' visible={showSelectLayout}>
                <SelectLayout
                  formData={formValues}
                  setData={setData}
                  isLoading={isLoading}
                  setCompanyMoveConfig={setCompanyMoveConfig}
                />
              </ShowHide>

              <ShowHide transition='fade' visible={showSelectProvider}>
                <SelectProvider formData={formValues} setData={setData} isLoading={isLoading} />
              </ShowHide>

              <ShowHide transition='fade' visible={showMovimentationType}>
                <SelectType formData={formValues} setData={setData} isLoading={isLoading} />
              </ShowHide>

              <ShowHide transition='fade' visible={showDownloadField}>
                <Alert formData={formValues} />

                <TemplateDownload
                  formData={formValues}
                  setData={setData}
                  triggerToast={triggerToast}
                />
              </ShowHide>

              <ShowHide transition='fade' visible={showUploadField}>
                <Row style={{ padding: '0', gap: '32px' }}>
                  <UploadFile setData={setData} />
                  <UploadFile setData={setData} isAttachment={true} />
                </Row>
              </ShowHide>

              <ShowHide transition='fade' visible={showUploadField}>
                <Action
                  isDisabled={isDisabledSubmit}
                  isLoading={isLoading}
                  onClick={() => handleSubmit()}
                />
              </ShowHide>
            </Grid>
          </form>
        </BoxInfo>
      </ShowHide>

      <ShowHide transition='fade' visible={showSubmitError}>
        <Errors details={useError.details} setUploadFormStatus={resetSubmitError} />
      </ShowHide>

      <ShowHide transition='fade' visible={showSubmitSuccess}>
        <Success />
      </ShowHide>

      {toast}
    </>
  );
};

export default Upload;
